import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { requestProvider } from "../../../services/request.service";
import { BACKEND_ROUTES } from "../../../utils/routes";
import secureLocalStorage from "react-secure-storage";
import { Button, Modal } from "react-bootstrap";

const CodeDetails = (props: any) => {
	const [codeId, setCodeId] = useState("");
	const [codeDetails, setCodeDetails] = useState<any>({} as any);
	const [ownerEmail, setOwnerEmail] = useState("");
	const value = useParams();
	const navigator = useNavigate();

	const [showClearModal, setShowClearModal] = useState(false);
	const [showStatusModal, setShowStatusModal] = useState(false);
	const [showTShirtModal, setShowTShirtModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [codeStatus, setCodeStatus] = useState("");
	const [tshirtCode, setTShirtCode] = useState("");
	const [shirtType, setShirtType] = useState("");
	const [status, setStatus] = useState("");
	const [qrStatuses, setQrStatuses] = useState<any>([]);
	const [tshirts, setTShirts] = useState<any>([]);

	useEffect(() => {
		if (!value) {
			navigator("/application-backdoor/dashboard");
		}

		getCodeDetails();
		getStatuses();
		getTShirts();

		// eslint-disable-next-line
	}, [value]);

	const getCodeDetails = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.CODE_DETAILS,
				"POST",
				{ code: value.value },
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					if (
						response.data === null ||
						response.data === undefined ||
						response.data === ""
					) {
						navigator("/application-backdoor/dashboard");
					}

					setCodeId(response.data._id);
					setCodeDetails(response.data.details);
					setCodeStatus(response.data.status);
					setShirtType(response.data?.type ?? "");

					// If code has details, get the owner email
					if (response.data.details) {
						getOwnerEmail(response.data.details.ownerId);
					} else {
						setOwnerEmail("");
					}
				})
				.catch((error) => {});
		} catch (error) {}
	};

	const getOwnerEmail = async (ownerId: string) => {
		try {
			requestProvider(
				BACKEND_ROUTES.USER_EMAIL,
				"POST",
				{ id: ownerId },
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					setOwnerEmail(response.data);
				})
				.catch((error) => {});
		} catch (error) {}
	};

	const clearCode = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.CLEAR_CODE,
				"POST",
				{ code: value.value },
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					window.location.reload();
				})
				.catch((error) => {});
		} catch (error) {}
	};

	const deleteCode = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.DELETE_CODE,
				"POST",
				{ code: value.value },
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					navigator("/application-backdoor/dashboard");
				})
				.catch((error) => {});
		} catch (error) {}
	};

	const getStatuses = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.QR_CODE_STATUSES,
				"GET",
				{},
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					setQrStatuses(response.data.data);
				})
				.catch((error) => {});
		} catch (error) {}
	};

	const getTShirts = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.TSHIRT_CODES,
				"GET",
				{},
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					setTShirts(response.data);
				})
				.catch((error) => {});
		} catch (error) {}
	};

	const changeStatus = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.UPDATE_QR_CODES,
				"POST",
				{ codeId, status },
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					window.location.reload();
				})
				.catch((error) => {});
		} catch (error) {}
	};

	const changeTShirt = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.UPDATE_QR_CODE_TSHIRT,
				"POST",
				{ codeId, tshirtCode: tshirtCode },
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					window.location.reload();
				})
				.catch((error) => {});
		} catch (error) {}
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-12">
					<h1>Code Details</h1>
				</div>
			</div>
			{codeId === "" ? (
				<div className="row">
					<div className="col-12">
						<p>No code found</p>
					</div>
				</div>
			) : (
				<>
					<div className="row">
						<div className="col-12">
							<p>Code Value: {value.value}</p>
							<div>
								<p>Owner: {ownerEmail}</p>
								<p>Content: {codeDetails?.content}</p>
								<p>Image URL: {codeDetails?.imageUrl}</p>
								<p>Link: {codeDetails?.link}</p>
								<p>Title: {codeDetails?.title}</p>
								<p>Status: {codeStatus}</p>
								<p>Type: {shirtType}</p>
							</div>
						</div>
					</div>
					<div className="row align-items-center justify-content-start ">
						<button
							className="btn btn-primary w-auto me-3 mt-2"
							onClick={() => {
								setStatus(codeStatus);
								setShowStatusModal(true);
							}}
						>
							Change Status
						</button>

						{/* Change tshirt button */}
						<button
							className="btn btn-primary w-auto me-3 mt-2"
							onClick={() => {
								if (tshirtCode === "" && tshirts.length > 0) {
									setTShirtCode(tshirts[0]);
								}
								setShowTShirtModal(true);
							}}
						>
							Change TShirt
						</button>

						<button
							className="btn btn-danger w-auto me-3 mt-2"
							onClick={() => {
								setShowClearModal(true);
							}}
						>
							Clear Code
						</button>

						<button
							className="btn btn-danger w-auto mt-2"
							onClick={() => {
								setShowDeleteModal(true);
							}}
						>
							Delete Code
						</button>
					</div>

					<Modal
						show={showClearModal}
						onHide={() => {
							setShowClearModal(false);
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>Confirm</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to clear the code?
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setShowClearModal(false);
								}}
							>
								Cancel
							</Button>
							<Button variant="danger" onClick={clearCode}>
								Clear
							</Button>
						</Modal.Footer>
					</Modal>

					<Modal
						show={showDeleteModal}
						onHide={() => {
							setShowDeleteModal(false);
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>Confirm</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete the code?
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setShowDeleteModal(false);
								}}
							>
								Cancel
							</Button>
							<Button variant="danger" onClick={deleteCode}>
								Delete
							</Button>
						</Modal.Footer>
					</Modal>

					<Modal
						show={showStatusModal}
						onHide={() => {
							setShowStatusModal(false);
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>Change code Status</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="form-group">
								<label htmlFor="status">Status</label>
								<select
									className="form-control"
									id="status"
									onChange={(e) => {
										setStatus(e.target.value);
									}}
									defaultValue={codeStatus}
								>
									{qrStatuses.map(
										(status: string, index: number) => (
											<option key={index} value={status}>
												{status}
											</option>
										)
									)}
								</select>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setShowStatusModal(false);
								}}
							>
								Cancel
							</Button>
							<Button variant="primary" onClick={changeStatus}>
								Change
							</Button>
						</Modal.Footer>
					</Modal>

					{/* Change tshirt modal */}
					<Modal
						show={showTShirtModal}
						onHide={() => {
							setShowTShirtModal(false);
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>Change code TShirt</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="form-group">
								<label htmlFor="tshirt">TShirt</label>
								<select
									className="form-control"
									id="tshirt"
									onChange={(e) => {
										setTShirtCode(e.target.value);
									}}
									defaultValue={shirtType}
								>
									{tshirts.map(
										(status: string, index: number) => (
											<option key={index} value={status}>
												{status}
											</option>
										)
									)}
								</select>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setShowTShirtModal(false);
								}}
							>
								Cancel
							</Button>
							<Button variant="primary" onClick={changeTShirt}>
								Change
							</Button>
						</Modal.Footer>
					</Modal>
				</>
			)}
		</div>
	);
};

export default CodeDetails;
