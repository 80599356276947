import { Translation } from "../../utils/translate";
import Navbar from "../QRinfo/navbar";

const CookiePolicy = () => {
	return Translation.GetLanguage() === "en" ? (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>Cookie Policy</h1>
				<p>
					The website wearyourspace.com is operated by the company
					WYSAPP SMARTWEAR S.R.L., headquartered in Ilfov County,
					Voluntari City, Bucuresti Nord Street, no. 10B, Building B1,
					Block C, 3rd Floor, Apartment 388, registered in the
					Registrul Comertului under number J23/1321/2023.
					<br />
					<br />
					This is the Cookie Policy for WearYourSpace, accessible from
					https://wearyourspace.com
				</p>
				<p>
					<strong>What Are Cookies</strong>
				</p>
				<p>
					As is common practice with almost all professional websites
					this site uses cookies, which are tiny files that are
					downloaded to your computer, to improve your experience.
					This page describes what information they gather, how we use
					it and why we sometimes need to store these cookies. We will
					also share how you can prevent these cookies from being
					stored however this may downgrade or &apos;break&apos;
					certain elements of the sites functionality.
				</p>
				<p>
					For more general information on cookies see the Wikipedia{" "}
					<a href="https://en.wikipedia.org/wiki/HTTP_cookie">
						article
					</a>{" "}
					on HTTP Cookies.
				</p>
				<p>
					<strong>How We Use Cookies</strong>
				</p>
				<p>
					We use cookies for a variety of reasons detailed below.
					Unfortunately in most cases there are no industry standard
					options for disabling cookies without completely disabling
					the functionality and features they add to this site. It is
					recommended that you leave on all cookies if you are not
					sure whether you need them or not in case they are used to
					provide a service that you use.
				</p>
				<p>
					<strong>Disabling Cookies</strong>
				</p>
				<p>
					You can prevent the setting of cookies by adjusting the
					settings on your browser (see your browser Help for how to
					do this). Be aware that disabling cookies will affect the
					functionality of this and many other websites that you
					visit. Disabling cookies will usually result in also
					disabling certain functionality and features of the this
					site. Therefore it is recommended that you do not disable
					cookies.
				</p>
				<p>
					<strong>The Cookies We Set</strong>
				</p>
				<ul>
					<li>
						<p>
							<strong>
								Cookies related to website functionality
							</strong>
						</p>
						<p>
							When you use our website, it uses cookies to
							function properly and to assist the usage of the
							WYSApp application.
						</p>
					</li>
				</ul>

				<p>
					<strong>More Information</strong>
				</p>
				<p>
					Hopefully that has clarified things for you and as was
					previously mentioned if there is something that you
					aren&apos;t sure whether you need or not it&apos;s usually
					safer to leave cookies enabled in case it does interact with
					one of the features you use on our site.
				</p>
				<p>
					However if you are still looking for more information then
					you can contact us through one of our preferred contact
					methods:
				</p>
				<ul>
					<li>
						Email:
						<a
							href="mailto:contact@wearyourspace.com
            "
						>
							contact@wearyourspace.com
						</a>
					</li>
				</ul>
			</div>
		</>
	) : (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>Cookie Policy</h1>

				<p>
					Site-ul wearyourspace.com este operat de societatea S.C.
					WYSAPP SMARTWEAR S.R.L. , cu sediul in Judetul Ilfov, Oras
					Voluntari, Sos. Bucuresti Nord, nr. 10B, Corp B1, Sc. C, Et.
					3, AP. 388 inscrisa in Registrul Comertului cu numarul
					J23/1321/2023.
					<br />
					<br />
					Aceasta este Politica de cookie-uri pentru WearYourSpace,
					accesibilă de la https://wearyourspace.com
				</p>
				<p>
					<strong>Ce sunt cookie-urile</strong>
				</p>
				<p>
					Ca și în cazul majorității site-urilor web profesionale,
					acest site utilizează cookie-uri, care sunt fișiere mici
					descărcate pe computerul dumneavoastră, în scopul
					îmbunătățirii experienței dumneavoastră. Această pagină
					descrie ce informații colectează acestea, cum le utilizăm și
					de ce uneori este necesar să stocăm aceste cookie-uri. Vom
					explica, de asemenea, cum puteți împiedica stocarea acestor
					cookie-uri, dar aceasta poate downgrade sau „rupt” anumite
					elemente ale site-ului funcționalitatea.
				</p>
				<p>
					Pentru informații mai generale despre cookie-uri, consultați{" "}
					<a href="https://ro.wikipedia.org/wiki/Cookie">articolul</a>{" "}
					Wikipedia despre cookie-uri HTTP.
				</p>
				<p>
					<strong>Cum utilizăm cookie-urile</strong>
				</p>
				<p>
					Folosim cookie-uri din mai multe motive detaliate mai jos.
					Din păcate, în majoritatea cazurilor nu există opțiuni
					standard în industrie pentru dezactivarea cookie-urilor fără
					a dezactiva complet funcționalitatea și caracteristicile pe
					care le adaugă acest site. Se recomandă să lăsați toate
					cookie-urile activate dacă nu sunteți sigur dacă le aveți
					nevoie sau nu, în cazul în care acestea sunt utilizate
					pentru a furniza un serviciu pe care îl utilizați.
				</p>
				<p>
					<strong>Dezactivarea cookie-urilor</strong>
				</p>
				<p>
					Puteți împiedica setarea cookie-urilor ajustând setările
					browserului dumneavoastră (consultați Ajutorul browserului
					pentru a afla cum să faceți acest lucru). Rețineți că
					dezactivarea cookie-urilor va afecta funcționalitatea
					acestui site și a multor alte site-uri pe care le vizitați.
					Dezactivarea cookie-urilor în general va duce și la
					dezactivarea anumitor funcționalități și caracteristici ale
					acestui site. Prin urmare, se recomandă să nu dezactivați
					cookie-urile.
				</p>
				<p>
					<strong>Cookie-urile pe care le setăm</strong>
				</p>
				<ul>
					<li>
						<p>
							<strong>
								Cookie-uri legate de funcționalitatea site-ului
							</strong>
						</p>
						<p>
							În cazul în care utilizați site-ul nostru, acesta
							folosește cookies pentru a putea funcționa corect și
							pentru a ajuta utilizarea aplicației WYSApp.
						</p>
					</li>
				</ul>

				<p>
					<strong>Mai multe informații</strong>
				</p>
				<p>
					Sperăm că acest lucru v-a clarificat lucrurile și, așa cum
					s-a menționat anterior, dacă există ceva de care nu sunteți
					sigur dacă aveți nevoie sau nu, de obicei este mai sigur să
					lăsați cookie-urile activate în cazul în care
					interacționează cu una dintre funcțiile pe care le utilizați
					pe site-ul nostru.
				</p>
				<p>
					Cu toate acestea, dacă căutați în continuare mai multe
					informații, ne puteți contacta prin una dintre metodele
					noastre preferate de contact:
				</p>
				<ul>
					<li>
						E-mail:
						<a
							href="mailto:contact@wearyourspace.com
            "
						>
							contact@wearyourspace.com
						</a>
					</li>
				</ul>
			</div>
		</>
	);
};

export default CookiePolicy;
