import React from 'react';

interface Props {
  text?: string ;
}

function detectLinks(text?: string ): boolean {
    const linkRegex = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm;
    if (typeof text === "string") {
        let value = linkRegex.test(text); 
        return value;
    }
    return false;
  }
  

const TextWithLinks: React.FC<Props> = ({ text }) => {
    const hasLinks = detectLinks(text);

    if (hasLinks && typeof text === "string") {
        const textWithLinks = text.replace(/([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm, (match) => {
            if (match.startsWith('http://') || match.startsWith('https://')) {
              return `<a href="${match}" target="_blank">${match}</a>`;
            } else {
              return `<a href="http://${match}" target="_blank">${match}</a>`;
            }
          });
        return <div dangerouslySetInnerHTML={{ __html: textWithLinks }} />;
    } else {
        return <div>{text}</div>;
    }
};

export default TextWithLinks;
