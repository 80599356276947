/* eslint-disable react/no-unstable-nested-components */
'use client';

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import * as React from 'react';
import {Container} from 'reactstrap';
import {
  Typography,
  Box,
  styled,
  type AccordionProps,
  type AccordionSummaryProps,
  type AccordionDetailsProps,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {Translation} from '../../../utils/translate';

export default function FAQ() {
  const theme = useTheme();
  const onMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const answerTextSize = onMobileDevice ? '14px' : '16px';

  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleAccordionChange =
		(panel: string) =>
		  (event: React.SyntheticEvent, newExpanded: boolean) => {
		    setExpanded(newExpanded ? panel : false);
		  };

  interface FAQItem {
    panelId: string
    question: string
    answer: string
  }

  // eslint-disable-next-line no-undef
  const QuestionElement: React.FC<FAQItem> = (props): JSX.Element => {
    const Accordion = styled((accordionProps: AccordionProps) => (
      <MuiAccordion disableGutters elevation={0} {...accordionProps} />
    ))(({theme}) => ({
      'borderRadius': '10px',

      'paddingLeft': '1rem',
      // paddingRight: '4rem',
      'paddingTop': '1rem',
      'paddingBottom': '1rem',

      'marginTop': theme.spacing(2),
      'marginBottom': theme.spacing(2),

      'border': expanded === props.panelId ? 'none' : '1px solid #D3D3D3',
      'background': expanded === props.panelId ? '#FFFFFF' : '#F7F5F5',
      'boxShadow':
				expanded === props.panelId ?
				  '0px 4px 16px 2px rgba(0, 0, 0, 0.05)' :
				  'none',

      '&:before': {
        display: 'none',
      },

      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0',
        paddingRight: '1rem',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      },

      '&:first-child': {
        borderRadius: '10px',
      },

      '&:last-child': {
        borderRadius: '10px',
      },
    }));

    const AccordionSummary = styled(
        (summartProps: AccordionSummaryProps) => (
          <MuiAccordionSummary
            expandIcon={
						expanded === props.panelId ?
						  (
							<RemoveIcon
							  sx={{
								  fontSize: '2rem',
								  color: 'rgba(232, 42, 73, 0.44)',
							  }}
							/>
						    ) :
						  (
							<AddIcon
							  sx={{fontSize: '2rem', color: '#595757'}}
							/>
						    )
            }
            {...summartProps}
          />
        ),
    )(({theme}) => ({
      'flexDirection': 'row-reverse',
      '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
      },
    }));

    const AccordionDetails = styled((props: AccordionDetailsProps) => (
      <MuiAccordionDetails {...props} />
    ))(({theme}) => ({
      marginLeft: '2.5rem',
    }));

    const CustomQuestionText = styled(Typography)(({theme}) => ({
      fontFamily: 'Poppins',
      color: '#595757',
      fontWeight: '500',
      fontSize: answerTextSize,
      marginLeft: '1rem',

      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
      },
    }));

    const CustomDetailText = styled(Typography)(({theme}) => ({
      fontFamily: 'Poppins',
      color: '#595757',
      fontWeight: '400',
      fontSize: answerTextSize,
      marginLeft: '1rem',

      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
      },
    }));

    return (
      <Accordion
        expanded={expanded === props.panelId}
        onChange={handleAccordionChange(props.panelId)}
      >
        <AccordionSummary>
          <CustomQuestionText>{props.question}</CustomQuestionText>
        </AccordionSummary>
        <AccordionDetails>
          <CustomDetailText>{props.answer}</CustomDetailText>
        </AccordionDetails>
      </Accordion>
    );
  };

  const CustomBox = styled(Box)(({theme}) => ({
    padding: '6rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 2rem',
    },
  }));

  const Title = styled(Typography)(({theme}) => ({
    fontSize: '32px',
    color: '#282828',
    fontWeight: '800',

    margin: '0 0 2rem 0',
  }));

  return (
    <Container id="faq-section">
      <CustomBox>
        <Title>{Translation.Translate('faq', 'title')}</Title>

        <Box sx={{width: '100%', maxWidth: '50rem'}}>
          <QuestionElement
            panelId="question1"
            question={Translation.Translate('faq', 'question1')}
            answer={Translation.Translate('faq', 'answer1')}
          />

          <QuestionElement
            panelId="question2"
            question={Translation.Translate('faq', 'question2')}
            answer={Translation.Translate('faq', 'answer2')}
          />

          <QuestionElement
            panelId="question3"
            question={Translation.Translate('faq', 'question3')}
            answer={Translation.Translate('faq', 'answer3')}
          />

          <QuestionElement
            panelId="question4"
            question={Translation.Translate('faq', 'question4')}
            answer={Translation.Translate('faq', 'answer4')}
          />

          <QuestionElement
            panelId="question5"
            question={Translation.Translate('faq', 'question5')}
            answer={Translation.Translate('faq', 'answer5')}
          />
        </Box>
      </CustomBox>
    </Container>
  );
}
