import "./AdminInterface.scss";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import env from "react-dotenv";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router";
import { HTTP_STATUS_CODES } from "../../utils/enums";

interface StateDetails {
	email: string;
	password: string;
	errorMessage: string;
	stage: number; // 0 for login 1 for other form
}

const AdminInterface = function (props: any) {
	const navigator: any = useNavigate();
	const [state, setState] = React.useState<StateDetails>({
		email: "",
		password: "",
		errorMessage: "",
		stage: 0,
	});

	// handler for login.
	const login = (event: React.SyntheticEvent) => {
		event.preventDefault();
		const CONTENT_URL = process.env.REACT_APP_BACKEND + "/auth/login-admin";
		const options = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				email: state.email,
				password: state.password,
			}),
		};

		fetch(CONTENT_URL, options)
			.then(async (response) => await response.json())
			.then((response) => {
				// if the response contains the access token, store it
				if (response.accessToken !== undefined) {
					secureLocalStorage.setItem(
						process.env.REACT_APP_ACCESS ?? "",
						response.accessToken
					);

					secureLocalStorage.setItem(
						process.env.REACT_APP_REFRESH ?? "",
						response.refreshToken
					);

					navigator("/application-backdoor/dashboard");
				} else {
					if (
						response.statusCode === HTTP_STATUS_CODES.UNAUTHORIZED
					) {
						setState({
							...state,
							errorMessage: "You are not an admin",
						});
					} else if (
						response.statusCode === HTTP_STATUS_CODES.BAD_REQUEST
					) {
						setState({
							...state,
							errorMessage: "Credentials incorrect",
						});
					} else {
						setState({
							...state,
							errorMessage: "Unable to connect",
						});
					}
				}
			})
			.catch((error) => {
				setState({
					...state,
					errorMessage: "Unable to connect",
				});
			});
	};

	// render the component
	return (
		<div className="Auth-form-container">
			<form className="Auth-form">
				{state.errorMessage.length > 0 && (
					<div className="alert alert-danger my-3" role="alert">
						{state.errorMessage}
					</div>
				)}
				<div className="Auth-form-content">
					<h3 className="Auth-form-title">Login</h3>
					<div className="form-group mt-3">
						<label>Email address</label>
						<input
							type="email"
							className="form-control mt-1"
							placeholder="Enter email"
							onChange={(e) => {
								setState({
									...state,
									email: e.target.value,
								});
							}}
						/>
					</div>
					<div className="form-group mt-3">
						<label>Password</label>
						<input
							type="password"
							className="form-control mt-1"
							placeholder="Enter password"
							onChange={(e) => {
								setState({
									...state,
									password: e.target.value,
								});
							}}
						/>
					</div>
					<div className="d-grid gap-2 mt-3">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={login}
						>
							Login
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default AdminInterface;
