import React, { useEffect } from "react";
import "../AdminInterface.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { requestProvider } from "../../../services/request.service";
import { BACKEND_ROUTES } from "../../../utils/routes";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { ITEMS_PER_PAGE } from "../../../utils/constants";
import Pagination from "@mui/material/Pagination";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router";
import { Button, Modal } from "react-bootstrap";

interface StateDetails {
	tshirts: number;
	transactionData: any[];

	openModal: boolean;

	pageIndex: number;
	tshirtCode: string;
	tshirtColor: string;
	tshirtManufacturer: string;
	tshirtIcon: File | null;
	tshirtSize: string;
	tshirtModel: string;
	tshirtType: string;
	alertMessage: string;
}

const TShirts = function (props: any) {
	const [state, setState] = React.useState<StateDetails>({
		tshirts: 0,
		transactionData: [],
		// scanData: "",
		openModal: false,
		// pageState: PageState.DASHBOARD,
		pageIndex: 0,
		tshirtCode: "",
		tshirtColor: "",
		tshirtManufacturer: "",
		tshirtIcon: null,
		tshirtSize: "",
		tshirtModel: "",
		tshirtType: "",
		alertMessage: "",
	});
	const navigator = useNavigate();

	useEffect(() => {
		getTShirts();
		// eslint-disable-next-line
	}, []);

	const changePage = (event: any, page: number) => {
		setState({
			...state,
			pageIndex: page - 1,
		});
	};

	// get the codes from the backend
	const getTShirts = async () => {
		requestProvider(
			BACKEND_ROUTES.ALL_TSHIRTS,
			"GET",
			{},
			{
				"Content-Type": "application/json",
				Authorization: `Bearer ${secureLocalStorage.getItem(
					process.env.REACT_APP_ACCESS ?? ""
				)}`,
			}
		).then((response) => {
			setState({
				...state,
				transactionData: response.data,
			});
		});
	};

	const createTShirt = async () => {
		// Check if all fields are filled
		if (
			state.tshirtCode === "" ||
			state.tshirtColor === "" ||
			state.tshirtManufacturer === "" ||
			state.tshirtIcon === null ||
			state.tshirtSize === "" ||
			state.tshirtModel === "" ||
			state.tshirtType === ""
		) {
			setState({
				...state,
				alertMessage: "Please fill all fields",
			});
			return;
		}

		// Check the image size
		if (state.tshirtIcon.size > 10 * 1024 * 1024) {
			setState({
				...state,
				alertMessage: "Image size too big. Max: 1MB",
			});
			return;
		}

		const formData = new FormData();
		formData.append("serialCode", state.tshirtCode);
		formData.append("color", state.tshirtColor);
		formData.append("manufacturer", state.tshirtManufacturer);
		formData.append("icon", state.tshirtIcon);
		formData.append("size", state.tshirtSize);
		formData.append("model", state.tshirtModel);
		formData.append("type", state.tshirtType);

		requestProvider(BACKEND_ROUTES.CREATE_TSHIRT, "POST", formData, {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${secureLocalStorage.getItem(
				process.env.REACT_APP_ACCESS ?? ""
			)}`,
		})
			.then((response) => {
				if (response.data.message) {
					setState({
						...state,
						alertMessage: response.data.message,
					});
					return;
				}

				// Reload
				window.location.reload();
			})
			.catch((error) => {
				setState({
					...state,
					alertMessage: "Something went wrong",
				});
			});
	};

	return (
		// Sticky navbar with one button with text "Delete code"
		<div className="admin-interface">
			{/* // A list of all the codes without form */}
			<div className="container">
				{/* Create tshirt button */}
				<div className="row">
					<div className="col-md-12">
						<div className="card mt-4">
							<div className="card-body">
								<button
									className="btn btn-primary"
									onClick={() => {
										setState({
											...state,
											openModal: true,
											alertMessage: "",
										});
									}}
								>
									Create tshirt
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<div className="card mt-4">
							<div className="card-body">
								<Table className="w-100">
									<Thead>
										<Tr>
											<Th>Nr.</Th>
											<Th>Serial Code</Th>
											{/* <Th>Status</Th> */}
											<Th>Actions</Th>
										</Tr>
									</Thead>

									<Tbody>
										{state.transactionData
											.slice(
												state.pageIndex *
													ITEMS_PER_PAGE,
												(state.pageIndex + 1) *
													ITEMS_PER_PAGE
											)
											.map((transaction, index) => (
												<Tr key={index}>
													<Td>
														{state.pageIndex *
															ITEMS_PER_PAGE +
															index +
															1}
													</Td>
													<Td>
														{transaction.serialCode}
													</Td>
													{/* <Td>
														{transaction.status ??
															"-"}
													</Td> */}
													<Td>
														<button
															className="btn btn-primary"
															onClick={() => {
																navigator(
																	"/application-backdoor/tshirt/" +
																		transaction.serialCode
																);
															}}
														>
															<FaEye className="m-0 p-0" />
														</button>
													</Td>
												</Tr>
											))}
									</Tbody>
								</Table>

								{state.transactionData.length >
									ITEMS_PER_PAGE && (
									<Pagination
										className="cards-pagination mt-5 align-items-center justify-content-center"
										count={
											Math.floor(
												state.transactionData.length /
													ITEMS_PER_PAGE
											) + 1
										}
										variant="outlined"
										shape="rounded"
										onChange={changePage}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Modal for creating tshirt */}
			<Modal
				show={state.openModal}
				onHide={() => {
					setState({
						...state,
						openModal: false,
					});
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>TShirt</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<label htmlFor="tshirtName">TShirt code</label>
						<input
							type="text"
							className="form-control"
							id="tshirtName"
							placeholder="Enter tshirt code"
							onChange={(e) => {
								setState({
									...state,
									tshirtCode: e.target.value,
								});
							}}
						/>

						<label htmlFor="tshirtColor">TShirt color</label>
						<input
							type="text"
							className="form-control"
							id="tshirtColor"
							placeholder="Enter tshirt color"
							onChange={(e) => {
								setState({
									...state,
									tshirtColor: e.target.value,
								});
							}}
						/>

						<label htmlFor="tshirtManufacturer">
							TShirt manufacturer
						</label>
						<input
							type="text"
							className="form-control"
							id="tshirtManufacturer"
							placeholder="Enter tshirt manufacturer"
							onChange={(e) => {
								setState({
									...state,
									tshirtManufacturer: e.target.value,
								});
							}}
						/>

						<label htmlFor="tshirtSize">Tshirt size</label>
						<input
							type="text"
							className="form-control"
							id="tshirtSize"
							placeholder="Enter tshirt size"
							onChange={(e) => {
								setState({
									...state,
									tshirtSize: e.target.value,
								});
							}}
						/>

						<label htmlFor="tshirtModel">Tshirt model</label>
						<input
							type="text"
							className="form-control"
							id="tshirtModel"
							placeholder="Enter tshirt model"
							onChange={(e) => {
								setState({
									...state,
									tshirtModel: e.target.value,
								});
							}}
						/>

						<label htmlFor="tshirtType">Tshirt type</label>
						<input
							type="text"
							className="form-control"
							id="tshirtType"
							placeholder="Enter tshirt type"
							onChange={(e) => {
								setState({
									...state,
									tshirtType: e.target.value,
								});
							}}
						/>

						<label htmlFor="tshirtIcon">TShirt icon</label>
						<input
							type="file"
							accept=".jpg, .jpeg, .png"
							className="form-control"
							id="tshirtIcon"
							placeholder="Enter tshirt icon"
							onChange={(e) => {
								setState({
									...state,
									tshirtIcon: e.target.files![0],
								});
							}}
						/>

						<br />
						{/* Code is used alert message */}
						<div
							className="alert alert-danger"
							role="alert"
							hidden={!state.alertMessage}
						>
							{state.alertMessage}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => {
							setState({
								...state,
								openModal: false,
							});
						}}
					>
						Close
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							createTShirt();
						}}
					>
						Create
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default TShirts;
