/* eslint-disable react/jsx-pascal-case */
// import Image from 'next/image'
import "./Home.scss";
import Hero from "./sections/hero";
import Guide from "./sections/guide";
import About from "./sections/about";
import FAQ from "./sections/faq";
import Download from "./sections/download";

export default function Home() {
	return (
		<main className="homepage">
			<Hero />
			<Guide />
			<About />
			<FAQ />
			<Download />
		</main>
	);
}
