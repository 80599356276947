import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { FaEye } from "react-icons/fa";
import { ITEMS_PER_PAGE } from "../../../utils/constants";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { requestProvider } from "../../../services/request.service";
import { BACKEND_ROUTES } from "../../../utils/routes";
import secureLocalStorage from "react-secure-storage";
import { HTTP_STATUS_CODES } from "../../../utils/enums";
import Pagination from "@mui/material/Pagination";
import { Autocomplete, TextField } from "@mui/material";

enum PageState {
	DASHBOARD,
	USER_DETAILS,
}

interface StateDetails {
	users: number;
	transactionData: any[];
	scanData: string;
	openModal: boolean;

	pageState: PageState;
	pageIndex: number;

	searchResults: Array<string>;
	usersToDisplay: any[];
}

const UsersDashboard = () => {
	const [state, setState] = React.useState<StateDetails>({
		users: 0,
		transactionData: [],
		scanData: "",
		openModal: false,

		pageState: PageState.DASHBOARD,
		pageIndex: 0,

		searchResults: [],
		usersToDisplay: [],
	});

	const [searchTerm, setSearchTerm] = React.useState("");
	const [timeoutId, setTimeoutId] = React.useState<any>(null);

	const navigator = useNavigate();

	useEffect(() => {
		getUsers();
	}, []);

	useEffect(() => {
		// Perform the search when the searchTerm changes
		performSearch();
	}, [searchTerm]);

	const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
		setState((prevState) => ({
			...prevState,
			pageIndex: page - 1,
		}));
	};

	const performSearch = () => {
		// Clear the previous timeout if any
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		if (searchTerm.length > 2) {
			// Set a new timeout
			const id = setTimeout(() => {
				const usersToDisplay = getSearchResults(searchTerm);

				const searchResults = usersToDisplay.map((user) => user.email);

				setState((prevState) => ({
					...prevState,
					searchResults,
					usersToDisplay,
				}));
			}, 1000);
		} else {
			setState((prevState) => ({
				...prevState,
				searchResults: [],
				usersToDisplay: prevState.transactionData,
			}));
		}

		// Store the timeoutId in state for later reference
		setTimeoutId(timeoutId);
	};

	const getSearchResults = (searchTerm: string) => {
		// Filter the results based on the search term
		return state.transactionData.filter((user) =>
			user.email.toLowerCase().includes(searchTerm.toLowerCase())
		);
	};

	const getUsers = () => {
		requestProvider(
			BACKEND_ROUTES.ALL_USERS,
			"GET",
			{},
			{
				Authorization: `Bearer ${secureLocalStorage.getItem(
					process.env.REACT_APP_ACCESS ?? ""
				)}`,
			}
		)
			.then((response) => {
				if (response.data.statusCode === HTTP_STATUS_CODES.OK) {
					setState((prevState) => ({
						...prevState,
						transactionData: response.data.data,
						usersToDisplay: response.data.data,
					}));
				} else {
					navigator("/");
				}
			})
			.catch((error) => {
				navigator("/");
			});
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const getUserId = (email: string) => {
		const user = state.transactionData.find((user) => user.email === email);

		if (user) {
			return user._id;
		}

		return "";
	};

	return (
		<div className="admin-interface">
			{/* Search bar */}
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="card mt-4">
							<div className="card-body">
								<Autocomplete
									id="search-bar"
									freeSolo
									autoComplete
									autoHighlight
									options={state.searchResults}
									renderInput={(params) => (
										<TextField
											{...params}
											onChange={handleInputChange}
											variant="outlined"
											label="Search Box"
										/>
									)}
									// Make options clickable and redirect to the user details page
									onChange={(event, value) => {
										if (value) {
											navigator(
												"/application-backdoor/user/" +
													getUserId(value)
											);
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Table */}
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="card mt-4">
							<div className="card-body">
								<Table className="w-100">
									<Thead>
										<Tr>
											<Th>Nr.</Th>
											<Th>Email</Th>
											<Th>Name</Th>
											<Th>Surname</Th>
											<Th>Actions</Th>
										</Tr>
									</Thead>

									<Tbody>
										{state.usersToDisplay

											.slice(
												state.pageIndex *
													ITEMS_PER_PAGE,
												(state.pageIndex + 1) *
													ITEMS_PER_PAGE
											)
											.map((transaction, index) => (
												<Tr key={index}>
													<Td>
														{state.pageIndex *
															ITEMS_PER_PAGE +
															index +
															1}
													</Td>
													<Td>{transaction.email}</Td>
													<Td>
														{transaction.firstName ??
															"-"}
													</Td>
													<Td>
														{transaction.lastName ??
															"-"}
													</Td>
													<Td>
														<button
															className="btn btn-primary"
															onClick={() => {
																navigator(
																	"/application-backdoor/user/" +
																		transaction._id
																);
															}}
														>
															<FaEye className="m-0 p-0" />
														</button>
													</Td>
												</Tr>
											))}
									</Tbody>
								</Table>

								{state.usersToDisplay.length >
									ITEMS_PER_PAGE && (
									<Pagination
										className="cards-pagination mt-5 align-items-center justify-content-center"
										count={
											Math.floor(
												state.usersToDisplay.length /
													ITEMS_PER_PAGE
											) + 1
										}
										variant="outlined"
										shape="rounded"
										onChange={changePage}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UsersDashboard;
