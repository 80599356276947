import { Translation } from "../../utils/translate";
import Navbar from "../QRinfo/navbar";

const TermsAndConditions = () => {
	return Translation.GetLanguage() === "ro" ? (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>Termini si Conditii utilizare WysApp </h1>
				<p>
					<strong>1. Informații despre Site</strong>
				</p>
				<p>
					Site-ul web Wearyourspace.com (denumit în continuare
					&quot;Wearyourspace.com&quot;) este proprietatea firmei S.C.
					Wysapp Smartwear S.R.L., cu sediul social în Voluntari, Sos
					București Nord 10B, județul Ilfov, Cod postal 077190,
					înregistrată la Registrul Comerțului cu numărul
					J23/1321/2023, cod unic de înregistrare fiscală RO47715141,
					cu adresa de email: contact@wearyourspace.com.
					<br /> <br />
					Wearyourspace.com este un site web de comerț electronic,
					care oferă produse din domeniul fashion personalizate cu un
					cod QR, ce pot fi customizate prin intermediul aplicației
					mobile Wear your space (denumită în continuare
					&quot;Wysapp&quot;). Prezentele termeni și condiții conțin
					dispoziții obligatorii pentru orice utilizator, înregistrat
					sau neînregistrat, pe site-ul Wearyourspace.com. Prin
					plasarea unei comenzi pe Wearyourspace.com, confirmați
					acordul cu Termenii și Condițiile Generale de Vânzare
					Wearyourspace.com.
					<br /> <br />
				</p>

				<p>
					<strong>2. Proprietatea Intelectuală</strong>
				</p>
				<p>
					Wearyourspace.com vă autorizează să vizualizați și să
					descărcați copii ale materialelor de pe site-ul său doar în
					scopuri personale și necomerciale. Utilizatorii pot trimite
					comentarii, sugestii sau observații cu privire la conținutul
					și structura site-ului, cu condiția ca acestea să nu fie
					ilegale, amenințătoare sau defăimătoare. Este interzisă
					trimiterea către Wearyourspace.com a mesajelor care conțin
					viruși sau spam. Conținutul site-ului, integral sau parțial,
					nu poate fi reprodus, duplicat, copiat, revândut sau
					exploatat în scopuri comerciale fără acceptul scris
					prealabil al Wearyourspace.com.
					<br /> <br />
					Pentru orice întrebări referitoare la drepturile de autor,
					vă rugăm să ne contactați la contact@wearyourspace.com.
					<br /> <br />
				</p>

				<p>
					<strong>
						3. Confidențialitatea Datelor și Protecția Vieții
						Private
					</strong>
				</p>

				<p>
					Wearyourspace.com respectă confidențialitatea datelor
					personale ale utilizatorilor săi. Toate informațiile
					furnizate de utilizatori vor fi tratate în conformitate cu
					Politica de Confidențialitate a site-ului. Utilizatorii au
					dreptul de a solicita accesul, corectarea sau ștergerea
					datelor lor personale prin trimiterea unei cereri scrise
					către firma Wysapp Smartwear S.R.L. la adresa de email
					contact@wearyourspace.com.
					<br /> <br />
				</p>

				<p>
					<strong>
						4. Dreptul de Retur conform Legislației Române
					</strong>
				</p>

				<p>
					În conformitate cu legislația românească, utilizatorii au
					dreptul de a returna produsele achiziționate de pe
					Wearyourspace.com în termen de 14 zile calendaristice de la
					primirea acestora, fără a fi nevoiți să
					<br /> <br />
					justifice decizia de retragere din contract și fără a
					suporta alte costuri decât cele directe de returnare a
					produselor. Pentru mai multe informații despre procedura de
					retur, vă rugăm să consultați secțiunea dedicată
					Returnărilor pe site-ul nostru.
					<br /> <br />
				</p>

				<p>
					<strong>5. Schimbări ale Site-ului</strong>
				</p>

				<p>
					Wearyourspace.com își rezervă dreptul de a suspenda,
					modifica, adăuga sau șterge în orice moment porțiuni ale
					conținutului său. De asemenea, își rezervă dreptul de a
					restricționa accesul utilizatorilor la o parte sau la
					întregul său conținut.
					<br /> <br />
				</p>

				<p>
					<strong>
						6. Înregistrare, Parole și Drepturi și Responsabilități
						ale Utilizatorilor
					</strong>
				</p>

				<p>
					Ca utilizator al acestui site, aveți dreptul la informare și
					corectitudine, iar personalul care deservește acest site va
					face tot posibilul pentru a facilita comunicarea cu
					dumneavoastră. Cu toate că informațiile publicate pe acest
					site au caracter informativ, personalul Wearyourspace.com nu
					garantează pentru recomandările și informațiile furnizate
					prin alte mijloace de comunicare, precum telefonul, emailul
					sau serviciul direct de asistență.
					<br /> <br />
					Statutul de membru al acestui site este la liberă alegere,
					iar înregistrarea implică furnizarea de date reale, complete
					și exacte. Este responsabilitatea dvs. să mențineți
					confidențialitatea datelor de acces ale contului
					dumneavoastră. Datele personale vor fi utilizate numai în
					scopul declarat al acestui site și nu vor fi făcute publice
					sau vândute. Wearyourspace.com nu va fi responsabilă pentru
					nicio pagubă morală sau materială cauzată de nerespectarea
					acestei prevederi.
					<br /> <br />
					Pentru a plasa comenzi online, datele din formularul de
					Facturare și Livrare trebuie să fie corecte, complete și
					verificabile. În caz contrar, comanda poate fi respinsă.
					<br /> <br />
				</p>

				<p>
					<strong>7. Dispute și Conflicte</strong>
				</p>

				<p>
					Orice încercare de acces neautorizat la site sau de fraudă
					va fi raportată autorităților competente. Orice conflict
					între companie și clienți se va încerca să fie rezolvat pe
					cale amiabilă, iar în caz contrar, se vor aplica prevederile
					legale românești. Wearyourspace.com nu garantează
					acuratețea, corectitudinea sau actualitatea informațiilor
					sau serviciilor furnizate de site. Termenii și Condițiile se
					supun legislației române, iar în caz de litigiu, se va
					încerca inițial o rezolvare pe cale amiabilă. Dacă nu se
					poate ajunge la o înțelegere, va fi considerată competentă
					instanța judecătorească.
				</p>
			</div>
		</>
	) : (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>WysApp Terms and Conditions of Use</h1>
				<p>
					<strong>1. Information about the Site</strong>
				</p>
				<p>
					The website Wearyourspace.com (hereinafter referred to as
					&quot;Wearyourspace.com&quot;) is owned by the company S.C.
					Wysapp Smartwear S.R.L., with registered office in
					Voluntari, Sos București Nord 10B, Ilfov County, Postal code
					077190, registered with the Trade Registry under number
					J23/1321/2023, fiscal registration code RO47715141, with
					email address: contact@wearyourspace.com.
					<br /> <br />
					Wearyourspace.com is an e-commerce website that offers
					fashion products personalized with a QR code, which can be
					customized through the Wear your space mobile application
					(hereinafter referred to as &quot;Wysapp&quot;). These terms
					and conditions contain mandatory provisions for any user,
					whether registered or unregistered, on the Wearyourspace.com
					website. By placing an order on Wearyourspace.com, you agree
					to the General Terms and Conditions of Sale of
					Wearyourspace.com.
					<br /> <br />
				</p>

				<p>
					<strong>2. Intellectual Property</strong>
				</p>
				<p>
					Wearyourspace.com authorizes you to view and download copies
					of materials from its site for personal and non-commercial
					purposes only. Users may submit comments, suggestions, or
					observations regarding the content and structure of the
					website, provided that they are not illegal, threatening, or
					defamatory. Sending messages containing viruses or spam to
					Wearyourspace.com is prohibited. The content of the website,
					in whole or in part, may not be reproduced, duplicated,
					copied, resold, or exploited for commercial purposes without
					the prior written consent of Wearyourspace.com.
					<br /> <br />
					For any questions regarding copyright, please contact us at
					contact@wearyourspace.com.
					<br /> <br />
				</p>

				<p>
					<strong>
						3. Data Confidentiality and Privacy Protection
					</strong>
				</p>

				<p>
					Wearyourspace.com respects the personal data confidentiality
					of its users. All information provided by users will be
					treated in accordance with the Privacy Policy of the
					website. Users have the right to request access, correction,
					or deletion of their personal data by sending a written
					request to the company Wysapp Smartwear S.R.L. at the email
					address contact@wearyourspace.com.
					<br /> <br />
				</p>

				<p>
					<strong>
						4. Right of Return in accordance with Romanian
						Legislation
					</strong>
				</p>

				<p>
					In accordance with Romanian legislation, users have the
					right to return products purchased from Wearyourspace.com
					within 14 calendar days of receiving them, without having to
					justify the decision to withdraw from the contract and
					without bearing any costs other than the direct return
					costs. For more information about the return procedure,
					please refer to the Returns section on our website.
					<br /> <br />
				</p>

				<p>
					<strong>5. Changes to the Site</strong>
				</p>

				<p>
					Wearyourspace.com reserves the right to suspend, modify,
					add, or delete portions of its content at any time. It also
					reserves the right to restrict users&apos; access to some or
					all of its content.
					<br /> <br />
				</p>

				<p>
					<strong>
						6. Registration, Passwords, and Rights and
						Responsibilities of Users
					</strong>
				</p>

				<p>
					As a user of this site, you have the right to information
					and correctness, and the staff serving this site will do
					everything possible to facilitate communication with you.
					Although the information published on this site is for
					informational purposes only, the staff of Wearyourspace.com
					does not guarantee the recommendations and information
					provided through other means of communication, such as
					telephone, email, or direct assistance.
					<br /> <br />
					Membership status on this site is at your free choice, and
					registration involves providing real, complete, and accurate
					data. It is your responsibility to maintain the
					confidentiality of your account access data. Personal data
					will only be used for the declared purpose of this site and
					will not be made public or sold. Wearyourspace.com shall not
					be liable for any moral or material damage caused by your
					failure to comply with this provision.
					<br /> <br />
					To place orders online, the data in the Billing and Delivery
					form must be correct, complete, and verifiable. Otherwise,
					the order may be rejected.
					<br /> <br />
				</p>

				<p>
					<strong>7. Disputes and Conflicts</strong>
				</p>

				<p>
					Any attempt of unauthorized access to the site or fraud will
					be reported to the competent authorities. Any conflict
					between the company and customers will be attempted to be
					resolved amicably, and otherwise, Romanian legal provisions
					will apply. Wearyourspace.com does not guarantee the
					accuracy, correctness, or timeliness of the information or
					services provided by the site. The Terms and Conditions are
					subject to Romanian law, and in case of litigation, an
					attempt will be made for an amicable resolution initially.
					If an agreement cannot be reached amicably, the competent
					court will be considered.
				</p>
			</div>
		</>
	);
};

export default TermsAndConditions;
