import { Outlet } from "react-router";
import "./MainLayout.scss";
import { Link } from "react-router-dom";

const MainLayout = () => {
	return (
		<>
			<Outlet />

			<footer className="w-100 mb-5 py-5 mt-auto">
				<div className="container">
					<div className="row align-items-center ">
						<div className="col-6 col-md-3">
							<Link to="/" className="copyright">
								© 2024 - WearYourSpace
							</Link>
						</div>
						<div className="col-6 col-md-9 d-flex flex-column align-items-start links">
							<Link to="/cookie-policy">Cookie Policy</Link>
							<Link to="/terms-and-conditions">
								Terms and Conditions
							</Link>

							<Link to="/gdpr">Privacy Policy</Link>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default MainLayout;
