import { Translation } from "../../utils/translate";
import Navbar from "../QRinfo/navbar";

const GDPR = () => {
	return Translation.GetLanguage() === "ro" ? (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>
					Politică de Confidențialitate cu privire la Prelucrarea
					Datelor cu Caracter Personal{" "}
				</h1>

				<p>
					<strong>I. Informații generale</strong>
				</p>
				<p>
					Wysapp Smartwear S.R.L., cu sediul social în Voluntari, Sos
					București Nord 10B, județ Ilfov, Cod poștal 077190,
					înregistrată la Registrul Comerțului cu numărul
					J23/1321/2023, cod unic de înregistrare fiscală RO47715141,
					este operator de date cu caracter personal și este înscrisă
					în registrul de evidență a prelucrărilor de date cu caracter
					personal sub nr. 12445. Datele cu caracter personal vor fi
					colectate și prelucrate numai în măsura permisă prin
					Regulamentul General (UE) 2016/679 privind protecția
					persoanelor fizice în ceea ce privește prelucrarea datelor
					cu caracter personal și privind libera circulație a acestor
					date (GDPR).
					<br /> <br />
					Accesul, vizitarea și utilizarea oricăror facilități oferite
					de site-ul wearyourspace.com se supun acordului dvs. asupra
					Politicii de Confidențialitate și Termenilor și Condițiilor
					de Utilizare. Acestea se aplică pentru oricare dintre
					secțiunile acestui site și pentru orice vizitator, deci în
					cazul în care nu sunteți de acord să le respectați, vă rugăm
					să nu folosiți site-ul wearyourspace.com. Aceste acorduri
					reprezintă singura convenție dintre Wysapp Smartwear SRL și
					utilizatorii site-ului wearyourspace.com.
					<br /> <br />
					Ne rezervăm dreptul de a actualiza și modifica periodic
					această Politică de Confidențialitate, pentru a reflecta
					orice modificări ale modului în care prelucrăm datele
					dumneavoastră cu caracter personal sau orice modificări ale
					cerințelor legale. În cazul oricărei astfel de modificări,
					vom afișa pe site-ul nostru versiunea modificată a Politicii
					de Confidențialitate, motiv pentru care vă rugăm să
					verificați periodic conținutul acestei Politici de
					Confidențialitate.
					<br /> <br />
				</p>

				<p>
					<strong>
						II. Categorii de Date cu Caracter Personal Vizate:
					</strong>
				</p>
				<p>
					În general, colectăm datele dvs. cu caracter personal direct
					de la dumneavoastră, astfel încât aveți controlul asupra
					tipului de informație pe care ne-o oferiți.
					<br /> <br />
					Putem, de asemenea, să colectăm și să prelucrăm ulterior
					anumite informații cu privire la comportamentul dvs. în
					timpul vizitării site-ului nostru web, pentru a vă
					personaliza experiența online și a vă pune la dispoziție
					oferte adaptate profilului dvs. Vă invităm să aflați mai
					multe detalii în acest sens prin consultarea secțiunii
					privitoare la scopurile prelucrării de mai jos.
					<br /> <br />
					Pe site-ul nostru web putem stoca și colecta informații în
					cookie-uri și tehnologii similare, conform Politicii de
					Cookie-uri.
					<br /> <br />
					Nu colectăm și nu prelucrăm în alt mod date sensibile,
					incluse de Regulamentul General privind Protecția Datelor în
					categorii speciale de date cu caracter personal.
					<br /> <br />
				</p>

				<p>
					<strong>III. Scopurile și Temeiurile Prelucrării</strong>
				</p>

				<p>
					Vom utiliza datele dvs. cu caracter personal în următoarele
					scopuri:
					<br /> <br />
					- Pentru prestarea serviciilor wearyourspace.com în
					beneficiul dvs.:
					<br /> <br />
					a) Crearea și administrarea contului dvs;
					<br /> <br />
					b) Prelucrarea comenzilor, inclusiv preluarea, validarea,
					expedierea și facturarea acestora;
					<br /> <br />
					c) Soluționarea anulărilor sau a problemelor de orice natură
					referitoare la o comandă, la bunurile sau serviciile
					achiziționate;
					<br /> <br />
					d) Returnarea produselor conform prevederilor legale;
					<br /> <br />
					e) Rambursarea contravalorii produselor conform prevederilor
					legale;
					<br /> <br />
					f) Asigurarea serviciilor de suport, inclusiv oferirea de
					răspunsuri la întrebările dvs. cu privire la comenzile dvs.
					<br /> <br />
					- Pentru îmbunătățirea serviciilor noastre:
					<br /> <br />
					Putem colecta și utiliza anumite informații în legătură cu
					comportamentul dvs. de cumpărător.
					<br /> <br />
					Pentru marketing:
					<br /> <br />
					-Vrem să vă ținem la curent cu privire la cele mai bune
					oferte pentru produsele care vă interesează. În acest sens,
					vă putem trimite mesaje conținând informații generale și
					tematice, informații cu privire la produse similare sau
					complementare cu cele pe care le-ați achiziționat,
					informații cu privire la oferte sau promoții, informații
					referitoare la produse adăugate în secțiunea “Cont/Cosul
					meu” sau ați arătat interes să le achiziționați, precum și
					alte comunicări comerciale.
					<br /> <br />
				</p>

				<p>
					<strong>IV. Dreptul la Ștergerea Datelor:</strong>
				</p>

				<p>
					Conform prevederilor GDPR, vă este garantat dreptul de a
					cere ștergerea datelor dvs. personale. Puteți solicita acest
					lucru în orice moment, trimițând o cerere la adresa noastră
					de e-mail, contact@wearyourspace.com. Vom da curs cererii
					dvs. și vom șterge toate datele dvs. personale, sub rezerva
					păstrării anumitor informații în situațiile în care
					legislația aplicabilă sau interesele noastre legitime o
					impun. Este important să rețineți că acest drept poate fi
					exercitat în anumite condiții, conform legislației în
					vigoare.
					<br /> <br />
				</p>

				<p>
					<strong>
						V. Cum Protejăm Securitatea Datelor Dvs. cu Caracter
						Personal
					</strong>
				</p>

				<p>
					Ne angajăm să asigurăm securitatea datelor cu caracter
					personal prin implementarea unor măsuri tehnice și
					organizatorice adecvate, conform standardelor industriei.
					<br /> <br />
					În ciuda măsurilor luate pentru a proteja datele dvs. cu
					caracter personal, vă atragem atenția că transmiterea
					informațiilor prin Internet, în general, sau prin
					intermediul altor rețele publice, nu este complet sigură,
					existând riscul ca datele să fie văzute și utilizate de
					către terțe părți neautorizate. Nu putem fi responsabili
					pentru astfel de vulnerabilități ale unor sisteme care nu
					sunt sub controlul nostru.
					<br /> <br />
				</p>

				<p>
					<strong>VI. Ce Drepturi Aveți:</strong>
				</p>

				<p>
					Prin citirea prezentei Politici de Protecția Datelor cu
					Caracter Personal, ați luat la cunoștință faptul că vă sunt
					garantate drepturile prevăzute de GDPR, respectiv:
					<br /> <br />
					- dreptul la transparența informațiilor, a comunicărilor și
					a modalităților de exercitare a drepturilor dvs.,
					<br /> <br />
					- dreptul la informare și acces la datele dvs. cu caracter
					personal,
					<br /> <br />
					- dreptul la rectificare și dreptul la ștergerea datelor,
					<br /> <br />
					- dreptul la restricționarea prelucrării,
					<br /> <br />
					- dreptul la portabilitatea datelor,
					<br /> <br />
					- dreptul la opoziție și
					<br /> <br />
					- de a nu fi supus unui procesul decizional individual
					automatizat,
					<br /> <br />
					- dreptul de a vă adresa Autorității de Supraveghere
					(ANSPDCP) în caz de încălcare a drepturilor dvs. garantate
					de legislația privind protecția datelor cu caracter
					personal.
					<br /> <br />
					Pentru exercitarea drepturilor mai sus menționate sau pentru
					orice întrebare legată de modul în care datele dumneavoastră
					sunt utilizate ori dacă unele dintre datele despre
					dumneavoastră sunt incorecte, ne puteți contacta prin e-mail
					la dpo@wearyourspace.com sau printr-o adresă scrisă, datată
					și semnată prin poștă sau curier la adresa: S.C. Wysapp
					Smartwear Online S.R.L., Voluntari, Sos București Nord 10B,
					Corp B1, Sc C, et 3, ap 388, județ Ilfov.
				</p>
			</div>
		</>
	) : (
		<>
			<div className="navbar-custom-container mb-3">
				<Navbar />
			</div>
			<div className="container">
				<h1>
					Privacy Policy regarding the Processing of Personal Data{" "}
				</h1>

				<p>
					<strong>I. General Information</strong>
				</p>
				<p>
					Wysapp Smartwear S.R.L., headquartered in Voluntari, Sos
					București Nord 10B, Ilfov County, Postal Code 077190,
					registered with the Trade Register under number
					J23/1321/2023, fiscal registration code RO47715141, is a
					personal data operator and is registered in the register of
					personal data processing under no. 12445. Personal data will
					be collected and processed only to the extent permitted by
					Regulation (EU) 2016/679 on the protection of individuals
					with regard to the processing of personal data and on the
					free movement of such data (GDPR).
					<br />
					<br />
					Accessing, visiting, and using any facilities offered by the
					wearyourspace.com website is subject to your agreement to
					the Privacy Policy and the Terms and Conditions of Use.
					These apply to any section of this site and to any visitor,
					so if you do not agree to abide by them, please do not use
					the wearyourspace.com website. These agreements represent
					the sole convention between Wysapp Smartwear SRL and users
					of the wearyourspace.com website.
					<br />
					<br />
					We reserve the right to periodically update and modify this
					Privacy Policy to reflect any changes in how we process your
					personal data or any changes in legal requirements. In the
					event of any such changes, we will display the modified
					version of the Privacy Policy on our website, so please
					check the content of this Privacy Policy periodically.
					<br />
					<br />
				</p>

				<p>
					<strong>II. Categories of Personal Data Concerned:</strong>
				</p>
				<p>
					In general, we collect your personal data directly from you,
					so you have control over the type of information you provide
					us.
					<br />
					<br />
					We may also collect and subsequently process certain
					information about your behavior during your visit to our
					website in order to personalize your online experience and
					provide you with offers tailored to your profile. We invite
					you to find out more details in this regard by consulting
					the section regarding the processing purposes below.
					<br />
					<br />
					On our website, we may store and collect information in
					cookies and similar technologies, in accordance with the
					Cookie Policy.
					<br />
					<br />
					We do not collect or otherwise process sensitive data, as
					included by the General Data Protection Regulation in
					special categories of personal data.
					<br />
					<br />
				</p>

				<p>
					<strong>
						III. Purposes and Legal Bases of Processing:
					</strong>
				</p>

				<p>
					Purposes and Legal Bases of Processing:
					<br />
					<br />
					We will use your personal data for the following purposes:
					<br />
					<br />
					-To provide wearyourspace.com services for your benefit:
					<br />
					<br />
					a) Creating and managing your account;
					<br />
					<br />
					b) Processing orders, including taking, validating,
					shipping, and invoicing them;
					<br />
					<br />
					c) Handling cancellations or any issues related to an order,
					the goods, or services purchased;
					<br />
					<br />
					d) Returning products in accordance with legal provisions;
					<br />
					<br />
					e) Refunding the value of products in accordance with legal
					provisions;
					<br />
					<br />
					f) Providing support services, including providing answers
					to your questions regarding your orders.
					<br />
					<br />
					-For the improvement of our services:
					<br />
					<br />
					We may collect and use certain information regarding your
					buyer behavior.
					<br />
					<br />
					-For marketing:
					<br />
					<br />
					We want to keep you informed about the best offers for
					products that interest you. In this regard, we may send you
					messages containing general and thematic information,
					information about similar or complementary products to those
					you have purchased, information about offers or promotions,
					information about products added to the &quot;My
					Account/Shopping Cart&quot; section, or in which you have
					shown interest, as well as other commercial communications.
					<br />
					<br />
				</p>

				<p>
					<strong>IV. Right to Data Deletion:</strong>
				</p>

				<p>
					In accordance with GDPR provisions, you are guaranteed the
					right to request the deletion of your personal data. You can
					request this at any time by sending a request to our email
					address, contact@wearyourspace.com. We will comply with your
					request and delete all your personal data, subject to
					retaining certain information where applicable law or our
					legitimate interests require it. It is important to note
					that this right may be exercised under certain conditions,
					in accordance with applicable law.
					<br />
					<br />
				</p>

				<p>
					<strong>
						V. How We Protect the Security of Your Personal Data:
					</strong>
				</p>

				<p>
					We are committed to ensuring the security of personal data
					by implementing adequate technical and organizational
					measures, following industry standards.
					<br />
					<br />
					Despite the measures taken to protect your personal data,
					please note that transmitting information over the Internet,
					in general, or via other public networks, is not entirely
					secure, and there is a risk that data may be seen and used
					by unauthorized third parties. We cannot be held responsible
					for such vulnerabilities of systems that are not under our
					control.
					<br />
					<br />
				</p>

				<p>
					<strong>VI. Your Rights:</strong>
				</p>

				<p>
					By reading this Privacy Policy, you acknowledge that you are
					guaranteed the rights provided by the GDPR, namely:
					<br />
					<br />
					- The right to transparent information, communications, and
					the exercise of your rights;
					<br />
					<br />
					- The right to information and access to your personal data;
					<br />
					<br />
					- The right to rectification and the right to erasure of
					data;
					<br />
					<br />
					- The right to restrict processing;
					<br />
					<br />
					- The right to data portability;
					<br />
					<br />
					- The right to object and
					<br />
					<br />
					- Not to be subject to an individual automated
					decision-making process,
					<br />
					<br />
					- The right to address the Supervisory Authority (ANSPDCP)
					in case of violation of your rights guaranteed by personal
					data protection legislation.
					<br />
					<br />
					To exercise the above-mentioned rights or for any questions
					related to how your data is used or if any of your data is
					incorrect, you can contact us by email at
					dpo@wearyourspace.com or by sending a written, dated, and
					signed letter by post or courier to the following address:
					S.C. Wysapp Smartwear Online S.R.L., Voluntari, Sos
					București Nord 10B, Corp B1, Sc C, et 3, ap 388, Ilfov
					County.
				</p>
			</div>
		</>
	);
};

export default GDPR;
