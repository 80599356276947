import React from 'react';
import '../AdminInterface.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import secureLocalStorage from 'react-secure-storage';
import {CSVLink} from 'react-csv';
import * as Papa from 'papaparse';
import {BACKEND_ROUTES} from '../../../utils/routes';
import {requestProvider} from '../../../services/request.service';

interface StateDetails {
  codes: number
  transactionData: any[]
}

class CodeGeneration extends React.Component<{}, StateDetails> {
  public csvLink: any;

  public constructor(props: any) {
    super(props);
    this.csvLink = React.createRef();

    this.state = {
      codes: 0,
      transactionData: [],
    };
  }

  // handler for generating codes
  public generateCodes = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const data = {
      number: this.state.codes,
    };

    requestProvider(BACKEND_ROUTES.CODE_GENERATION, 'POST', data, {
      Authorization:
				'Bearer ' +
				secureLocalStorage.getItem(process.env.REACT_APP_ACCESS ?? ''),
    })
        .then((response) => {
          const data = Papa.parse(response.data, {delimiter: '\n'});

          this.setState({
            transactionData: data.data,
          });
        })
        .catch((error) => {
          this.setState({
            transactionData: [],
          });
        });
  };

  public componentDidUpdate(
      prevProps: Readonly<{}>,
      prevState: Readonly<StateDetails>,
      snapshot?: any,
  ): void {
    if (this.csvLink && this.state.transactionData.length > 0) {
      this.csvLink.current.link.click();
    }
  }

  // render the component
  public render() {
    return (
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Generate</h3>

            <div className="form-group mt-3">
              <label>Number of codes</label>
              <input
                type="number"
                className="form-control mt-1"
                placeholder="Enter number of codes"
                min="1"
                max="1000"
                onChange={(e) => {
								  this.setState({
								    codes: Number(e.target.value),
								  });
                }}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={this.generateCodes}
              >
								GenerateCodes
              </button>
            </div>
          </div>
        </form>

        <CSVLink
          data={this.state.transactionData}
          filename="data.csv"
          style={{display: 'none'}}
          ref={this.csvLink}
          target="_blank"
        />
      </div>
    );
  }
}

export default CodeGeneration;
