import { useEffect, useState } from "react";
import { requestProvider } from "../../../services/request.service";
import { BACKEND_ROUTES } from "../../../utils/routes";
import secureLocalStorage from "react-secure-storage";
import { stat } from "fs";
import { Modal } from "reactstrap";
import { IconButton, ImageList, ImageListItem } from "@mui/material";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { hover } from "@testing-library/user-event/dist/hover";

interface GalleryImage {
	filename: string;
	url: string;
}

interface StateDetails {
	// Image urls
	images: Array<GalleryImage>;

	// Add image modal
	openModal: boolean;
	imageToAdd: File;
}

// Gallery page for admin to add images to the gallery and delete them
const Gallery = (props: any) => {
	const [state, setState] = useState<StateDetails>({
		// Image urls
		images: [],
		// Add image modal
		openModal: false,
		imageToAdd: new File([], ""),
	});

	useEffect(() => {
		getGalleryImages();
	}, []);

	const getGalleryImages = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.GET_GALLERY_IMAGES,
				"GET",
				{},
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			).then((response) => {
				if (response.data?.data) {
					response.data.data.forEach((image: any) => {
						fetchImage(image.image);
					});
				}
			});
		} catch (error) {}
	};

	const fetchImage = async (filename: string) => {
		const imageUrl =
			process.env.REACT_APP_BACKEND +
			BACKEND_ROUTES.GALLERY_IMAGE +
			filename;

		fetch(imageUrl, {
			method: "GET",
		})
			.then(async (response) => {
				if (response.status === 500) {
					return undefined;
				}

				return await response.blob();
			})
			.then((blob) => {
				if (blob !== undefined) {
					// setIconUrl(URL.createObjectURL(blob));

					let newState = state;

					newState.images.push({
						filename: filename,
						url: URL.createObjectURL(blob),
					});
					setState({
						...newState,
					});
				}
			})
			.catch((err) => {});
	};

	const addImage = async (image: File) => {
		const formData = new FormData();
		formData.append("image", image);

		try {
			requestProvider(
				BACKEND_ROUTES.ADD_GALLERY_IMAGE,
				"POST",
				formData,
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			).then((response) => {
				window.location.reload();
			});
		} catch (error) {}
	};

	const removeImage = async (filename: string) => {
		try {
			requestProvider(
				BACKEND_ROUTES.REMOVE_GALLERY_IMAGE,
				"POST",
				{ filename: filename },
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			).then((response) => {
				if (response.data) {
					let newState = state;
					newState.images = newState.images.filter((image) => {
						return image.filename !== filename;
					});
					setState({
						...newState,
					});
				}
			});
		} catch (error) {}
	};

	return (
		<div className="admin-interface">
			{/* Add image modal */}
			<Modal
				isOpen={state.openModal}
				toggle={() => {
					setState({
						...state,
						openModal: !state.openModal,
					});
				}}
			>
				<div className="modal-header">
					<h5 className="modal-title">Add image</h5>
					<button
						type="button"
						className="close"
						onClick={() => {
							setState({
								...state,
								openModal: !state.openModal,
							});
						}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">
					{/* Add image form */}
					<form>
						<div className="form-group">
							<label htmlFor="image">Image</label>
							<input
								type="file"
								accept=".jpg,.jpeg,.png"
								className="form-control"
								id="image"
								onChange={(event) => {
									if (event.target.files) {
										setState({
											...state,
											imageToAdd: event.target.files[0],
										});
									}
								}}
							/>
						</div>
					</form>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-primary"
						onClick={() => {
							addImage(state.imageToAdd);
						}}
					>
						Add image
					</button>
				</div>
			</Modal>

			<div className="container">
				<div className="row">
					<div className="col-12">
						<h1>Gallery</h1>
					</div>
				</div>
				{/* Add image button */}
				<div className="row">
					<div className="col-12">
						<button
							className="btn btn-primary"
							onClick={() => {
								setState({
									...state,
									openModal: true,
								});
							}}
						>
							Add image
						</button>
					</div>
				</div>

				<br />

				{/* Gallery images */}
				<div className="row">
					<ImageList
						cols={5}
						// Remove scroll bar
						sx={{
							overflow: "hidden",
						}}
					>
						{state.images.map((image) => (
							<ImageListItem key={image.filename}>
								<img
									src={image.url}
									alt="image"
									loading="lazy"
								/>
								{/* Remove image button */}
								<ImageListItemBar
									sx={{
										background:
											"linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
											"rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
										border: "none",
										"&:hover": {
											background:
												"linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
												"rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
										},
									}}
									position="top"
									actionPosition="right"
									className="btn btn-danger"
									onClick={() => {
										removeImage(image.filename);
									}}
									actionIcon={
										<IconButton
											sx={{
												color: "rgba(255, 255, 255, 0.54)",
											}}
											aria-label="remove item"
										>
											<HighlightOffIcon />
										</IconButton>
									}
								/>
							</ImageListItem>
						))}
					</ImageList>
				</div>
			</div>
		</div>
	);
};

export default Gallery;
