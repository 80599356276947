/* eslint-disable max-params */
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import {HTTP_STATUS_CODES} from '../utils/enums';

export const requestProvider = async (
    urlPath: string,
    method: string,
    data: any,
    headers: any,
    helpers: any = {},
) => {
  return (
    await axios({
      ...helpers,
      url: (process.env.REACT_APP_BACKEND ?? '') + urlPath,
      method,
      headers,
      data,
    })
    // Handle the response from backend here
        .then((res: any) => {
          if (res?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
            return refreshTkn()
                .then((res: any) => {
                  if (res === HTTP_STATUS_CODES.UNAUTHORIZED) {
                    return {};
                  }

                  headers.Authorization = `Bearer ${secureLocalStorage.getItem(
                      process.env.REACT_APP_ACCESS ?? '',
                  )}`;

                  return retryRequest(
                      urlPath,
                      method,
                      data,
                      headers,
                      helpers,
                  )
                      .then((res: any) => res)
                      .catch((err: any) => {
                        throw err;
                      });
                })
                .catch((err: any) => {
                  throw err;
                });
          }

          return res;
        })

    // Catch errors if any
        .catch((err: any) => {
          if (err?.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
            return refreshTkn()
                .then((res: any) => {
                  if (res === HTTP_STATUS_CODES.UNAUTHORIZED) {
                    return {};
                  }

                  headers.Authorization = `Bearer ${secureLocalStorage.getItem(
                      process.env.REACT_APP_ACCESS ?? '',
                  )}`;

                  return retryRequest(
                      urlPath,
                      method,
                      data,
                      headers,
                      helpers,
                  )
                      .then((res: any) => res)
                      .catch((err: any) => {
                        throw err;
                      });
                })
                .catch((err: any) => {
                  throw err;
                });
          } else {
            throw err;
          }
        })
  );
};

const refreshTkn = (tries = 0): any => {
  return (
    axios({
      url: (process.env.REACT_APP_BACKEND ?? '') + '/auth/refresh',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem(
            process.env.REACT_APP_REFRESH ?? '',
        )}`,
      },
      data: {},
    })
    // Handle the response from backend here
        .then((res: any) => {
          if (res?.status === 200) {
            secureLocalStorage.setItem(
                process.env.REACT_APP_ACCESS ?? '',
                res.data.accessToken,
            );

            secureLocalStorage.setItem(
                process.env.REACT_APP_REFRESH ?? '',
                res.data.refreshToken,
            );
          }

          return res?.status;
        })

    // Catch errors if any
        .catch(async (err: any) => {
          if (
            err.response.status === HTTP_STATUS_CODES.FORBIDDEN ||
					err.status === HTTP_STATUS_CODES.FORBIDDEN
          ) {
            if (tries < 3) return await refreshTkn(tries + 1);
          } else {
            throw err;
          }
        })
  );
};

const retryRequest = async (
    urlPath: string,
    method: string,
    data: any,
    headers: any,
    helpers: any = {},
) => {
  return (
    await axios({
      ...helpers,
      url: (process.env.REACT_APP_BACKEND ?? '') + urlPath,
      method,
      headers,
      data,
    })
    // Handle the response from backend here
        .then((res: any) => {
          return res;
        })

    // Catch errors if any
        .catch((err: any) => {
          throw err;
        })
  );
};
