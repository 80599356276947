const translations: any = {
	en: {
		navbar: {
			home: "Home",
			howitworks: "How it works",
			about: "About us",
			faq: "FAQ's",
			download: "Download",
		},
		hero: {
			description:
				"Where fashion meets technology, empowering you to customize, connect, and share your unique story",
			shopnow: "Buy T-Shirt",
		},
		howitworks: {
			title: "How it works",
			"step1-title": "Your QR Code T-Shirt Adventure Begins",
			"step1-description":
				"Transform your wardrobe with QR t-shirt! Buy our trendy t-shirts embedded with customizable QR codes. Scan and claim your shirt to link it to your account and personalize the code's content. Express yourself, wear your unique story, and captivate the world with QR T-Shirt's digital fashion revolution",
			"step2-title": "Personalize, Share and Track Your T-Shirt",
			"step2-description":
				"WearYourSpace brings you the power of personalization. Design your unique t-shirt within our app, adding photos, text, and links to your customized QR code. Share your story and express yourself through your wearable canvas. Stay connected with the number of scans your shirt receives, tracking the impact you make. With WearYourSpace, your style speaks volumes.",
			"step3-title": "Feel the Magic of Wearyourspace T-Shirts",
			"step3-description":
				"Discover the exhilarating Wearyourspace experience! Scan your QR-coded t-shirts and immerse yourself in a world of personalized stories. Connect with vibrant individuals, explore their unique journeys, and engage with captivating content such as photos, text, and links. When you wear a Wearyourspace t-shirt, you become part of an extraordinary community, spreading inspiration and forging connections that transcend fashion.",
		},
		"about-us": {
			title: "About us",
			description:
				'Buy T-Shirt and Wear Your Story! Discover the perfect t-shirt with a customizable QR code that reflects your unique personality. Express yourself, share your passions, and make a statement. Click the "Buy T-Shirt" button and unlock a world of personalized fashion with Wearyourspace.',
			"shop-now": "Buy T-Shirt",
		},
		faq: {
			title: "FAQ's",
			question1: "Are the QR-coded t-shirts durable and of good quality?",
			answer1:
				"Absolutely! Wearyourspace takes pride in providing high-quality t-shirts that are not only fashionable but also durable. Our t-shirts are made from premium materials, ensuring comfort and longevity. You can confidently wear and showcase your personalized QR-coded t-shirt with pride.",
			question2: "How does the QR code customization work?",
			answer2:
				"With Wearyourspace, you can easily customize your QR code by adding photos, text, and links within the app. Simply select the t-shirt design you love, personalize the QR code's content, and make it uniquely yours.",
			question3: "How do I link the scanned t-shirt to my account?",
			answer3:
				"When you scan a Wearyourspace t-shirt for the first time, it automatically gets linked to your account. This ensures that you have full control over the customization and management of your t-shirt's QR code.",
			question4:
				"How can I track the number of scans my t-shirt receives?",
			answer4:
				"Wearyourspace provides built-in tracking functionality to help you monitor the engagement of your t-shirt. You can view the number of scans your t-shirt has received, allowing you to see the impact and reach of your personalized QR code.",
			question5:
				"Can I share my customized QR-coded t-shirt with others?",
			answer5:
				"Yes! Wearyourspace encourages you to share your personalized QR-coded t-shirt with friends, family, and the world. When someone scans your t-shirt, they'll be able to experience the unique content you've chosen to display, whether it's a photo, text, or a link. It's a great way to showcase your style and share your story with others.",
		},
		download: {
			title: "Download App",
			description:
				"Unleash your fashion revolution with Wearyourspace! Create custom QR-coded t-shirts that speak volumes about your unique style and story. Scan, customize, and connect with a vibrant community of fashion-forward individuals. Spark conversations, inspire others, and wear your identity proudly. Download the Wearyourspace app now from the Apple App Store or Google Play Store and embark on an extraordinary journey of self-expression.",
		},
	},
	ro: {
		navbar: {
			home: "Acasă",
			howitworks: "Cum funcționează",
			about: "Despre noi",
			faq: "Întrebări frecvente",
			download: "Descarcă",
		},
		hero: {
			description:
				"Unde moda întâlnește tehnologia, noi îți oferim posibilitatea de a personaliza, conecta și împărtăși povestea ta unică",
			shopnow: "Cumpără tricou",
		},
		howitworks: {
			title: "Cum funcționează",
			"step1-title": "Aventura ta cu tricoul tau cu QR Code începe",
			"step1-description":
				"Transformă-ți garderoba cu Tricoul cu QR! Cumpără tricourile noastre trendy cu coduri QR personalizabile. Scanează și revendică-ți tricoul pentru a-l lega de contul tău și pentru a personaliza conținutul codului. Exprimă-te, poartă-ți povestea unică și cucerește lumea cu revoluția digitală a modei Tricourilor QR",
			"step2-title":
				"Personalizează, împărtășește și urmărește-ți tricoul",
			"step2-description":
				"WearYourSpace îți oferă puterea personalizării. Proiectează-ți tricoul unic în aplicația noastră, adăugând fotografii, text și link-uri la codul QR personalizat. Rămâi conectat cu numărul de scanări pe care le primește tricoul tău, urmărind impactul pe care îl faci. Cu WearYourSpace, stilul tău vorbește de la sine.",
			"step3-title": "Simte magia tricourilor Wearyourspace",
			"step3-description":
				"Descoperă experiența Wearyourspace! Scanează-ți tricourile cu cod QR și vei fi introdus într-o lume de povești personalizate. Conectează-te cu persoane, explorează călătoriile lor unice și interacționează cu conținut captivant, cum ar fi fotografii, text și link-uri. Când porți un tricou Wearyourspace, devii parte a unei comunități extraordinare, răspândind inspirație și creând conexiuni care depășesc moda.",
		},
		"about-us": {
			title: "Despre noi",
			description:
				'Cumpără tricou și poartă-ți povestea! Descoperă tricoul perfect cu un cod QR personalizabil care reflectă personalitatea ta unică. Exprimă-te, împărtășește-ți pasiunile și fă o declarație. Apasă butonul "Cumpără tricou" și deblochează o lume a modei personalizate cu Wearyourspace.',
			"shop-now": "Cumpără tricou",
		},
		faq: {
			title: "Întrebări frecvente",
			question1:
				"Sunt tricourile cu cod QR durabile și de bună calitate?",
			answer1:
				"Absolut! Wearyourspace se mândrește cu furnizarea de tricouri de înaltă calitate, care nu sunt doar la modă, ci și durabile. Tricourile noastre sunt realizate din materiale premium, asigurând confort și longevitate. Poți purta și expune cu încredere tricoul tău cu cod QR personalizat cu mândrie.",
			question2: "Cum funcționează personalizarea codului QR?",
			answer2:
				"Cu Wearyourspace, poți personaliza ușor codul QR prin adăugarea de fotografii, text și link-uri în aplicație. Pur și simplu selectează designul tricoului pe care îl iubești, personalizează conținutul codului QR și fă-l unic.",
			question3: "Cum pot lega tricoul scanat de contul meu?",
			answer3:
				"Când scanezi un tricou Wearyourspace pentru prima dată, acesta se leagă automat la contul tău. Acest lucru asigură că ai control total asupra personalizării și gestionării codului QR al tricoului tău.",
			question4:
				"Cum pot urmări numărul de scanări pe care le primește tricoul meu?",
			answer4:
				"Wearyourspace oferă funcționalitatea de urmărire încorporată pentru a te ajuta să monitorizezi angajamentul tricoului tău. Poți vizualiza numărul de scanări pe care le-a primit tricoul tău, permițându-ți să vezi impactul codului QR personalizat.",
			question5:
				"Pot împărtăși tricoul meu personalizat cu cod QR cu alții?",
			answer5:
				"Da! Wearyourspace te încurajează să împărtășești tricoul tău personalizat cu cod QR cu prietenii, familia și restul lumii. Când cineva scanează tricoul tău, va putea experimenta conținutul unic pe care l-ai ales să îl afișezi, fie că este vorba de o fotografie, text sau un link. Este o modalitate excelentă de a-ți arăta stilul și de a-ți împărtăși povestea cu alții.",
		},
		download: {
			title: "Descarcă Aplicația",
			description:
				"Dezlănțuie revoluția modei cu Wearyourspace! Creează tricouri personalizate cu coduri QR care vorbesc despre stilul și povestea ta unică. Scanează, personalizează și conectează-te cu o comunitate vibrantă de persoane cu spirit de modă. Inițiază conversații, inspiră-i pe alții și poartă-ți cu mândrie identitatea. Descarcă acum aplicația Wearyourspace din Apple App Store sau Google Play Store și pornește într-o călătorie extraordinară a exprimării de sine.",
		},
	},
};

export default translations;
