"use client";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, styled, Typography } from "@mui/material";

import styles from "./guide.module.scss";
import { Container } from "reactstrap";
import { Translation } from "../../../utils/translate";

export default function Hero() {
	const theme = useTheme();
	const onMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

	const middleStepDirection = onMobileDevice ? "initial" : "row-reverse";

	const Stepper = styled(Box)(({ theme }) => ({
		position: "absolute",
		margin: "auto",
		left: "0",
		right: "0",
		top: "30rem",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			top: "50vw",
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	}));

	const StepLabel = styled(Box)(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		width: "72px",
		height: "72px",
		background: "linear-gradient(45deg, #E82A49 0%, #F46D40 100%)",
		borderRadius: "10px",
		[theme.breakpoints.down("md")]: {
			width: "50px",
			height: "50px",
		},
		[theme.breakpoints.down("sm")]: {
			width: "72px",
			height: "72px",
		},
	}));

	const StepLabelText = styled(Typography)(({ theme }) => ({
		margin: "auto",
		fontSize: "20px",
		color: "#fff",
	}));

	const StepSeparator = styled(Box)(({ theme }) => ({
		border: "1px dashed #E82A49",
		height: "50rem",
		[theme.breakpoints.down("md")]: {
			height: "40rem",
		},
	}));

	const StepBox = styled(Box)(({ theme }) => ({
		display: "flex",
		padding: "2rem",
		gap: "15rem",
		[theme.breakpoints.down("md")]: {
			gap: "5rem",
		},
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			gap: "1rem",
			marginBottom: "4rem",
		},
	}));

	const StepImage = styled(Box)(({ theme }) => ({
		flex: 1.2,
		display: "flex",
		justifyContent: "center",
		filter: "drop-shadow(0px 2.76726px 4px rgba(0, 0, 0, 0.030926))",

		[theme.breakpoints.down("md")]: {
			flex: 1,
		},
	}));

	const Title = styled(Typography)(({ theme }) => ({
		fontSize: "32px",
		color: "#282828",
		marginBottom: "2rem",
		[theme.breakpoints.down("md")]: {
			fontSize: "24px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "20px",
			fontWeight: "600",
			textAlign: "center",
			marginBottom: "1rem",
		},
	}));

	const Description = styled(Typography)(({ theme }) => ({
		color: "#595757",
		fontSize: "1rem",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			fontWeight: "400",
			textAlign: "center",
		},
	}));

	return (
		<Container id="guide-section" className={styles.guide_section}>
			<Box className={styles.guide_section_box}>
				<Typography
					color="#282828"
					fontSize="2rem"
					fontWeight={700}
					marginTop="1rem"
					marginBottom="2rem"
				>
					{Translation.Translate("howitworks", "title")}
				</Typography>

				{!onMobileDevice && (
					<Stepper>
						<StepLabel>
							<StepLabelText>1</StepLabelText>
						</StepLabel>
						<StepSeparator />
						<StepLabel>
							<StepLabelText>2</StepLabelText>
						</StepLabel>
						<StepSeparator />
						<StepLabel>
							<StepLabelText>3</StepLabelText>
						</StepLabel>
					</Stepper>
				)}

				{onMobileDevice && (
					<StepLabel>
						<StepLabelText>1</StepLabelText>
					</StepLabel>
				)}

				<StepBox>
					<StepImage>
						<img
							src="phone_step_1.png"
							alt="step1img"
							style={{ maxWidth: "100%" }}
						/>
					</StepImage>
					<Box sx={{ flex: 1, alignSelf: "center" }}>
						<Title>
							{Translation.Translate("howitworks", "step1-title")}
						</Title>
						<Description>
							{Translation.Translate(
								"howitworks",
								"step1-description"
							)}
						</Description>
					</Box>
				</StepBox>

				{onMobileDevice && (
					<StepLabel>
						<StepLabelText>2</StepLabelText>
					</StepLabel>
				)}

				<StepBox sx={{ flexDirection: middleStepDirection }}>
					<StepImage
						sx={{
							flex: 1.2,
							display: "flex",
							justifyContent: "center",
							filter: "drop-shadow(0px 2.76726px 4px rgba(0, 0, 0, 0.030926))",
						}}
					>
						<img
							src="phone_step_2.png"
							alt="step1img"
							style={{ maxWidth: "100%" }}
						/>
					</StepImage>
					<Box sx={{ flex: 1, alignSelf: "center" }}>
						<Title>
							{Translation.Translate("howitworks", "step2-title")}
						</Title>
						<Description>
							{Translation.Translate(
								"howitworks",
								"step2-description"
							)}
						</Description>
					</Box>
				</StepBox>

				{onMobileDevice && (
					<StepLabel>
						<StepLabelText>3</StepLabelText>
					</StepLabel>
				)}

				<StepBox>
					<StepImage
						sx={{
							flex: 1.2,
							display: "flex",
							justifyContent: "center",
							filter: "drop-shadow(0px 2.76726px 4px rgba(0, 0, 0, 0.030926))",
						}}
					>
						<img
							src="phone_step_3.png"
							alt="step1img"
							style={{ maxWidth: "100%" }}
						/>
					</StepImage>
					<Box sx={{ flex: 1, alignSelf: "center" }}>
						<Title>
							{Translation.Translate("howitworks", "step3-title")}
						</Title>
						<Description>
							{Translation.Translate(
								"howitworks",
								"step3-description"
							)}
						</Description>
					</Box>
				</StepBox>
			</Box>
		</Container>
	);
}
