export enum BACKEND_ROUTES {
	CONNECTED_ADMIN = "/auth/is-admin",
	ALL_CODES = "/qrcode/admin-get-all-codes",
	CODE_DETAILS = "/qrcode/admin-code-data",
	CLEAR_CODE = "/qrcode/admin-clear-code",
	CODE_GENERATION = "/qrcode/generate-multiple-codes",
	QR_CODE_STATUSES = "/qrcode/qr-code-statuses",
	UPDATE_QR_CODES = "/qrcode/qr-code-update-status",
	UPDATE_QR_CODE_TEMPLATE = "/qrcode/qr-code-update-template",
	ALL_TEMPLATES = "/template/admin-get-all-templates",
	CREATE_TEMPLATE = "/template/create",
	TEMPLATE_DETAILS = "/template/admin-template-data",
	CLEAR_TEMPLATE = "/template/admin-clear-template",
	TEMPLATE_ICON = "/guest/template-icon/",
	TEMPLATE_CODES = "/template/template-codes",

	DELETE_UNPRODUCED_CODES = "/qrcode/admin-delete-unproduced-codes",
	EXPORT_CODES = "/qrcode/admin-export-codes",

	USER_EMAIL = "/user/admin-get-email",
	UPDATE_QR_CODE_TSHIRT = "/qrcode/qr-code-update-tshirt",
	ALL_TSHIRTS = "/tshirt/admin-get-all-tshirts",
	CREATE_TSHIRT = "/tshirt/create",
	EDIT_TSHIRT = "/tshirt/edit",
	TSHIRT_DETAILS = "/tshirt/admin-tshirt-data",
	CLEAR_TSHIRT = "/tshirt/admin-clear-tshirt",
	TSHIRT_ICON = "/guest/tshirt-icon/",
	TSHIRT_CODES = "/tshirt/tshirt-codes",

	DELETE_CODE = "/qrcode/admin-delete-code",

	GET_GALLERY_IMAGES = "/gallery/get-images",
	ADD_GALLERY_IMAGE = "/gallery/add-image",
	REMOVE_GALLERY_IMAGE = "/gallery/remove-image",
	GALLERY_IMAGE = "/guest/gallery-image/",

	ALL_USERS = "/user/admin-get-all-users",
	USER_DETAILS = "/user/admin-user-data",
	USER_CODES = "/qrcode/admin-user-codes",
}
