import React, { useEffect, useState } from "react";
import "../AdminInterface.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { requestProvider } from "../../../services/request.service";
import { BACKEND_ROUTES } from "../../../utils/routes";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { ITEMS_PER_PAGE } from "../../../utils/constants";
import Pagination from "@mui/material/Pagination";
import { FaEye, FaFileExport } from "react-icons/fa";
import { BiExport } from "react-icons/bi";
import { useNavigate, useParams } from "react-router";

const UserDetails = () => {
	const value = useParams();
	const navigate = useNavigate();

	const [userId, setUserId] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	const [codes, setCodes] = useState<any[]>([]);
	const [pageIndex, setPageIndex] = useState(0);

	useEffect(() => {
		if (!value) {
			navigate("/application-backdoor/users");
		}

		getUserDetails();
		getCodes();
	}, []);

	const getUserDetails = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.USER_DETAILS,
				"POST",
				{
					id: value.id,
				},
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				}
			).then((res) => {
				if (res.status === 200) {
					setUserId(res.data.data.userId);
					setUserEmail(res.data.data.email);
					setFirstName(res.data.data.firstName);
					setLastName(res.data.data.lastName);
				} else {
					navigate("/application-backdoor/users");
				}
			});
		} catch (err) {}
	};

	const getCodes = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.USER_CODES,
				"POST",
				{
					userId: value.id,
				},
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				}
			).then((res) => {
				if (res.status === 200) {
					setCodes(res.data);
				}
			});
		} catch (err) {}
	};

	const changePage = (event: any, page: number) => {
		setPageIndex(page - 1);
	};

	return (
		<div className="admin-interface">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h1>
							User: {firstName} {lastName}
						</h1>
					</div>
				</div>
				{userId === "" ? (
					<div className="row">
						<div className="col-12">
							<p>No user found</p>
						</div>
					</div>
				) : (
					<div className="row">
						<div className="col-12">
							<div>
								<p>Email: {userEmail}</p>
								<p>First Name: {firstName}</p>
								<p>Last Name: {lastName}</p>
							</div>
						</div>
					</div>
				)}

				{/* Table with codes */}
				<h2>Codes</h2>

				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="card mt-4">
								<div className="card-body">
									<Table className="w-100">
										<Thead>
											<Tr>
												<Th>Nr.</Th>
												<Th>Value</Th>
												<Th>Status</Th>
												<Th>Actions</Th>
											</Tr>
										</Thead>

										<Tbody>
											{codes
												.slice(
													pageIndex * ITEMS_PER_PAGE,
													(pageIndex + 1) *
														ITEMS_PER_PAGE
												)
												.map((transaction, index) => (
													<Tr key={index}>
														<Td>
															{pageIndex *
																ITEMS_PER_PAGE +
																index +
																1}
														</Td>
														<Td>
															{transaction.value}
														</Td>
														<Td>
															{transaction.status ??
																"-"}
														</Td>
														<Td>
															<button
																className="btn btn-primary"
																onClick={() => {
																	navigate(
																		"/application-backdoor/code/" +
																			transaction.value
																	);
																}}
															>
																<FaEye className="m-0 p-0" />
															</button>
														</Td>
													</Tr>
												))}
										</Tbody>
									</Table>

									{codes.length > ITEMS_PER_PAGE && (
										<Pagination
											className="cards-pagination mt-5 align-items-center justify-content-center"
											count={
												Math.floor(
													codes.length /
														ITEMS_PER_PAGE
												) + 1
											}
											variant="outlined"
											shape="rounded"
											onChange={changePage}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserDetails;
