import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ClipLoader } from "react-spinners";
import "./QRinfo.scss";
import secureLocalStorage from "react-secure-storage";
import TextWithLinks from "./TextWithLinks";
import Navbar from "./navbar";

interface QRinfoState {
	details: {
		link?: string;
		content?: string;
		hasData: number;
		src?: any;
	};
}

class QRinfo extends React.Component<{}, QRinfoState> {
	public constructor(props: any) {
		super(props);

		this.state = {
			details: {
				link: "",
				content: "",
				hasData: 0,
				src: null,
			},
		};
	}

	public componentDidMount() {
		// obtain the href from the browser
		const HREF = window.location.href;

		// obtain the id from the href(last element)
		const ID = HREF.split("/").pop();

		const CONTENT_URL = process.env.REACT_APP_BACKEND + "/guest/code/" + ID;
		const SEE_CODE_URL = process.env.REACT_APP_BACKEND + "/guest/see-code/";
		const GET_UUID = process.env.REACT_APP_BACKEND + "/guest/uuid";

		// fetch the data from the server enabling cors
		fetch(CONTENT_URL, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		})
			.then(async (response) => await response.json())
			.then((info) => {
				const data = info.data;

				if (data === undefined) {
					this.setState({
						details: {
							...this.state.details,
							hasData: -1,
						},
					});

					return;
				}

				if (
					data.link !== null &&
					data.link !== undefined &&
					data.link !== ""
				) {
					if ((data.link as string).startsWith("https://")) {
						window.location.href = String(data.link);
					} else window.location.href = "https://" + data.link;
				} else if (data.content && data.content !== undefined) {
					const imageUrl =
						process.env.REACT_APP_BACKEND +
						"/guest/code-picture/" +
						ID;

					fetch(imageUrl, {
						method: "GET",
					})
						.then(async (response) => {
							if (response.status === 500) {
								return undefined;
							}

							return await response.blob();
						})
						.then((blob) => {
							if (blob === undefined) {
								this.setState({
									details: {
										...this.state.details,
										content: data.content,
										hasData: 1,
										src: "",
									},
								});
								return;
							}

							this.setState({
								details: {
									...this.state.details,
									content: data.content,
									hasData: 1,
									src: URL.createObjectURL(blob),
								},
							});
						})
						.catch((err) => {});
				} else {
					const imageUrl =
						process.env.REACT_APP_BACKEND +
						"/guest/code-picture/" +
						ID;

					fetch(imageUrl, {
						method: "GET",
					})
						.then(async (response) => {
							if (response.status !== 200) {
								throw new Error("No Image");
							}

							return await response.blob();
						})
						.then((blob) => {
							if (blob && blob !== undefined) {
								this.setState({
									details: {
										...this.state.details,
										content: data.content,
										hasData: 1,
										src: URL.createObjectURL(blob),
									},
								});
							} else {
								this.setState({
									details: {
										...this.state.details,
										hasData: -1,
									},
								});
							}
						})
						.catch((err) => {
							this.setState({
								details: {
									...this.state.details,
									hasData: -1,
								},
							});
						});
				}
			})
			.catch((error) => {
				window.location.href = "/";
			});

		// see the code request based on cookies

		if (
			secureLocalStorage.getItem(
				process.env.REACT_APP_COOKIE_CODE ?? "cookies"
			) === null
		) {
			fetch(GET_UUID, {
				method: "GET",
				headers: { "Content-Type": "application/json" },
			})
				.then(async (response) => {
					if (response.status !== 200) throw new Error("No UUID");
					return await response.json();
				})
				.then((info) => {
					secureLocalStorage.setItem(
						process.env.REACT_APP_COOKIE_CODE ?? "cookies",
						info.uuid
					);

					fetch(SEE_CODE_URL, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							uuid: info.uuid,
							code: ID,
						}),
					})
						.then(async (response) => {
							if (response.status !== 200) {
								throw new Error("Already Submitted");
							}
							return await response.json();
						})
						.then((info) => {})
						.catch((err) => {});
				})
				.catch((err) => {});
		} else {
			fetch(SEE_CODE_URL, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					uuid: secureLocalStorage.getItem(
						process.env.REACT_APP_COOKIE_CODE ?? "cookies"
					),
					code: ID,
				}),
			})
				.then(async (response) => {
					if (response.status !== 200) {
						throw new Error("Already submitted");
					}
					return await response.json();
				})
				.then((info) => {})
				.catch((err) => {});
		}
	}

	public render() {
		return (
			<main className="qr-info">
				<div className="navbar-custom-container">
					<Navbar />
				</div>
				<div className="qr-info-page">
					<ClipLoader
						color="#FF229FA1"
						loading={this.state.details.hasData === 0}
						size={50}
						aria-label="Loading Spinner"
						data-testid="loader"
						className="loader"
					/>

					{this.state.details.hasData === 1 && (
						<div className="content">
							{this.state.details.src && (
								<img alt="home" src={this.state.details.src} />
							)}
							<div className="text">
								<TextWithLinks
									text={this.state.details.content}
								/>
							</div>
						</div>
					)}

					{this.state.details.hasData === -1 && (
						<p className="no-information">
							There is no information about this code
						</p>
					)}
				</div>
			</main>
		);
	}
}

export default QRinfo;
