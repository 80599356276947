import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { requestProvider } from "../../../services/request.service";
import { BACKEND_ROUTES } from "../../../utils/routes";
import secureLocalStorage from "react-secure-storage";
import env from "react-dotenv";

import { Button, Modal } from "react-bootstrap";
import { HTTP_STATUS_CODES } from "../../../utils/enums";

const TShirtDetails = (props: any) => {
	const [tshirtCode, settshirtCode] = useState("");
	const [tshirtColor, setTShirtColor] = useState("");
	const [tshirtManufacturer, setTShirtManufacturer] = useState("");
	const [tshirtSize, setTshirtSize] = useState("");
	const [tshirtModel, setTshirtModel] = useState("");
	const [tshirtType, setTshirtType] = useState("");
	const [iconUrl, setIconUrl] = useState("");

	const [editTshirtCode, setEditTshirtCode] = useState("");
	const [editTshirtColor, setEditTShirtColor] = useState("");
	const [editTshirtManufacturer, setEditTShirtManufacturer] = useState("");
	const [editTshirtSize, setEditTshirtSize] = useState("");
	const [editTshirtModel, setEditTshirtModel] = useState("");
	const [editTshirtType, setEditTshirtType] = useState("");
	const [editIconUrl, setEditIconUrl] = useState<any>(null);
	const [alertMessage, setAlertMessage] = useState("");

	const value = useParams();
	const navigator = useNavigate();

	const [showClearModal, setShowClearModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);

	useEffect(() => {
		if (!value) {
			navigator("/application-backdoor/tshirt");
		}

		getTShirtData();

		fetchIcon();
	}, []);

	const fetchIcon = async () => {
		const imageUrl =
			process.env.REACT_APP_BACKEND +
			BACKEND_ROUTES.TSHIRT_ICON +
			value.code;

		fetch(imageUrl, {
			method: "GET",
		})
			.then(async (response) => {
				if (response.status === 500) {
					return undefined;
				}

				return await response.blob();
			})
			.then((blob) => {
				if (blob !== undefined) {
					setIconUrl(URL.createObjectURL(blob));
				}
			})
			.catch((err) => {});
	};

	const getTShirtData = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.TSHIRT_DETAILS,
				"POST",
				{ code: value.code },
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					settshirtCode(response.data._id);
					setTShirtColor(response.data.color);
					setTShirtManufacturer(response.data.manufacturer);
					setTshirtSize(response.data.size);
					setTshirtModel(response.data.model);
					setTshirtType(response.data.type);
				})
				.catch((error) => {});
		} catch (error) {}
	};

	const clearTShirt = async () => {
		try {
			requestProvider(
				BACKEND_ROUTES.CLEAR_TSHIRT,
				"POST",
				{ code: value.code },
				{
					Authorization: `Bearer ${secureLocalStorage.getItem(
						process.env.REACT_APP_ACCESS ?? ""
					)}`,
				},
				{}
			)
				.then((response) => {
					navigator("/application-backdoor/tshirts");
				})
				.catch((error) => {});
		} catch (error) {}
	};

	const editTshirt = async () => {
		// Check if all fields are filled
		if (
			editTshirtCode === "" ||
			editTshirtColor === "" ||
			editTshirtManufacturer === "" ||
			editTshirtSize === "" ||
			editTshirtModel === "" ||
			editTshirtType === ""
		) {
			setAlertMessage("Please fill all the fields");
			return;
		}

		// Check the image size
		if (editIconUrl && editIconUrl.size > 10 * 1024 * 1024) {
			setAlertMessage("Image size should be less than 10MB");
			return;
		}

		const formData = new FormData();
		formData.append("serialCode", editTshirtCode);
		formData.append("color", editTshirtColor);
		formData.append("manufacturer", editTshirtManufacturer);
		formData.append("size", editTshirtSize);
		formData.append("model", editTshirtModel);
		formData.append("type", editTshirtType);
		formData.append("id", tshirtCode);

		if (editIconUrl) {
			formData.append("icon", editIconUrl);
		}

		requestProvider(BACKEND_ROUTES.EDIT_TSHIRT, "POST", formData, {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${secureLocalStorage.getItem(
				process.env.REACT_APP_ACCESS ?? ""
			)}`,
		})
			.then((response) => {
				if (response.status === HTTP_STATUS_CODES.CREATED) {
					navigator("/application-backdoor/tshirts", {
						replace: true,
					});
					return;
				}

				if (response.data.message) {
					setAlertMessage(response.data.message);
					return;
				}

				// Reload
				navigator("/application-backdoor/tshirts", { replace: true });
			})
			.catch((error) => {
				setAlertMessage("Something went wrong");
			});
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-12">
					<h1>TShirt Details</h1>
				</div>
			</div>
			{tshirtCode === "" ? (
				<div className="row">
					<div className="col-12">
						<p>No tshirt found</p>
					</div>
				</div>
			) : (
				<>
					<div className="row">
						<div className="col-12">
							<p>TShirt Serial Code: {value.code}</p>
							<div>
								<p>Color: {tshirtColor}</p>
								<p>Manufacturer: {tshirtManufacturer}</p>
								<p>Size: {tshirtSize}</p>
								<p>Model: {tshirtModel}</p>
								<p>Type: {tshirtType}</p>
								{/* TShirt Icon, limit size */}
								<p>Icon:</p>
								<div>
									<img
										src={iconUrl}
										alt="TShirt Icon"
										className="img-fluid max-width-100"
										style={{ maxWidth: "300px" }}
									/>
								</div>
								<br />
							</div>
						</div>
					</div>
					<div className="row align-items-center justify-content-start">
						<button
							className="btn btn-danger w-auto me-3"
							onClick={() => {
								setShowClearModal(true);
							}}
						>
							Delete tshirt
						</button>

						<button
							className="btn btn-info w-auto"
							onClick={() => {
								setShowEditModal(true);
								setEditTShirtColor(tshirtColor);
								setEditTShirtManufacturer(tshirtManufacturer);
								setEditTshirtSize(tshirtSize);
								setEditTshirtModel(tshirtModel);
								setEditTshirtType(tshirtType);
								setEditTshirtCode(value.code!);
							}}
						>
							Edit tshirt
						</button>
					</div>

					<Modal
						show={showEditModal}
						onHide={() => {
							setShowEditModal(false);
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>TShirt</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="form-group">
								<label htmlFor="tshirtName">TShirt code</label>
								<input
									type="text"
									className="form-control"
									id="tshirtName"
									placeholder="Enter tshirt code"
									onChange={(e) => {
										setEditTshirtCode(e.target.value);
									}}
									defaultValue={editTshirtCode}
								/>

								<label htmlFor="tshirtColor">
									TShirt color
								</label>
								<input
									type="text"
									className="form-control"
									id="tshirtColor"
									placeholder="Enter tshirt color"
									onChange={(e) => {
										setEditTShirtColor(e.target.value);
									}}
									defaultValue={editTshirtColor}
								/>

								<label htmlFor="tshirtManufacturer">
									TShirt manufacturer
								</label>
								<input
									type="text"
									className="form-control"
									id="tshirtManufacturer"
									placeholder="Enter tshirt manufacturer"
									onChange={(e) => {
										setEditTShirtManufacturer(
											e.target.value
										);
									}}
									defaultValue={editTshirtManufacturer}
								/>

								<label htmlFor="tshirtSize">Tshirt size</label>
								<input
									type="text"
									className="form-control"
									id="tshirtSize"
									placeholder="Enter tshirt size"
									onChange={(e) => {
										setEditTshirtSize(e.target.value);
									}}
									defaultValue={editTshirtSize}
								/>

								<label htmlFor="tshirtModel">
									Tshirt model
								</label>
								<input
									type="text"
									className="form-control"
									id="tshirtModel"
									placeholder="Enter tshirt model"
									onChange={(e) => {
										setEditTshirtModel(e.target.value);
									}}
									defaultValue={editTshirtModel}
								/>

								<label htmlFor="tshirtType">Tshirt type</label>
								<input
									type="text"
									className="form-control"
									id="tshirtType"
									placeholder="Enter tshirt type"
									onChange={(e) => {
										setEditTshirtType(e.target.value);
									}}
									defaultValue={editTshirtType}
								/>

								<label htmlFor="tshirtIcon">TShirt icon</label>
								<input
									type="file"
									accept=".jpg, .jpeg, .png"
									className="form-control"
									id="tshirtIcon"
									placeholder="Enter tshirt icon"
									onChange={(e) => {
										if (
											e.target.files &&
											e.target.files?.length > 0
										)
											setEditIconUrl(e.target.files[0]);
									}}
								/>

								<br />
								{/* Code is used alert message */}
								<div
									className="alert alert-danger"
									role="alert"
									hidden={alertMessage.length < 1}
								>
									{alertMessage}
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setShowEditModal(false);
								}}
							>
								Close
							</Button>
							<Button
								variant="primary"
								onClick={() => {
									editTshirt();
								}}
							>
								Update
							</Button>
						</Modal.Footer>
					</Modal>

					<Modal
						show={showClearModal}
						onHide={() => {
							setShowClearModal(false);
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>Confirm</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to clear the tshirt?
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setShowClearModal(false);
								}}
							>
								Cancel
							</Button>
							<Button variant="danger" onClick={clearTShirt}>
								Delete
							</Button>
						</Modal.Footer>
					</Modal>
				</>
			)}
		</div>
	);
};

export default TShirtDetails;
