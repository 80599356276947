"use client";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, styled, Typography } from "@mui/material";
import styles from "./download.module.scss";

import { Container } from "reactstrap";
import { Translation } from "../../../utils/translate";

export default function Download() {
	const theme = useTheme();
	const onMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

	const CustomBox = styled(Box)(({ theme }) => ({
		maxWidth: "75rem",
		padding: "6rem 2rem",
		display: "flex",
		gap: "4rem",
		alignSelf: "center",
		marginLeft: "auto",
		marginRight: "auto",
		alignItems: "center",

		[theme.breakpoints.down("sm")]: {
			padding: "0 2rem",
			flexDirection: "column",
			alignItems: "center",
			gap: "2rem",
			paddingBottom: "4rem",
		},
	}));

	const Title = styled(Typography)(({ theme }) => ({
		fontSize: "32px",
		color: "#282828",
		fontWeight: "800",
		lineHeight: "2rem",

		margin: "4rem 0 2rem 0",
		[theme.breakpoints.down("sm")]: {
			marginTop: "2rem",
			width: "auto",
		},
	}));

	const Description = styled(Typography)(({ theme }) => ({
		color: "#595757",
		fontSize: "20px",
		fontWeight: "400",
		marginBottom: "2rem",

		[theme.breakpoints.down("md")]: {
			fontSize: "18px",
		},

		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
			fontSize: "18px",
			marginBottom: "4rem",
		},
	}));

	const StoreBox = styled(Box)(({ theme }) => ({
		display: "inline-flex",

		[theme.breakpoints.down("md")]: {
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "60vw",
			display: "flex",
		},
		[theme.breakpoints.down("sm")]: {
			maxWidth: "65vw",
			flexDirection: "column",
		},
	}));

	const StoreImage = styled("img")(({ theme }) => ({
		[theme.breakpoints.down("md")]: {
			width: "100%",
		},
	}));

	return (
		<Container id="download-section" className={styles.download_section}>
			<CustomBox>
				{onMobileDevice && (
					<>
						<Title>Download App</Title>
						<Box
							sx={{
								flex: "1",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<img
								src="download_img.png"
								alt="heroImg"
								style={{
									maxWidth: "100%",
									marginBottom: "1rem",
								}}
							/>
						</Box>
					</>
				)}

				<Box sx={{ flex: 1.5 }}>
					{!onMobileDevice && (
						<Title>
							{Translation.Translate("download", "title")}
						</Title>
					)}

					<Description>
						{Translation.Translate("download", "description")}
					</Description>
					<StoreBox>
						<a
							href="https://apps.apple.com/ro/app/wear-your-space/id6449791428"
							target="_blank"
							rel="noreferrer"
						>
							<StoreImage
								className="mt-2"
								src="app_store_button.png"
								alt="heroImg"
							/>
						</a>
						<a
							className={`${styles.button_download}`}
							href="https://play.google.com/store/apps/details?id=com.wys.app"
							target="_blank"
							rel="noreferrer"
						>
							<StoreImage
								className="mt-2"
								src="google_store_button.png"
								alt="heroImg"
							/>
						</a>
					</StoreBox>
				</Box>

				{!onMobileDevice && (
					<Box
						sx={{
							flex: "1",
							display: "flex",
							justifyContent: "center",
							paddingBottom: "auto",
							paddingTop: "auto",
							alignSelf: "center",
						}}
					>
						<img
							src="download_img.png"
							alt="heroImg"
							style={{
								maxWidth: "100%",
								marginBottom: "2rem",
							}}
						/>
					</Box>
				)}
			</CustomBox>
		</Container>
	);
}
