import translations from './translations';

export class Translation {
  public static language: string = localStorage.getItem('language') || 'ro';

  public static Translate(section: string, key: string) {
    const translation = translations[this.language];

    if (translation) {
      return translation[section][key];
    }

    // Fallback to English if translation not found
    return translations.en[section][key];
  }

  public static SetLanguage(language: string) {
    localStorage.setItem('language', language);
    this.language = language;

    // eslint-disable-next-line
		window.location.href = window.location.href;
  }

  public static GetLanguage() {
    return this.language;
  }
}
